
// import React from "react";
// import axios from 'axios';
// import { Line, Bar } from "react-chartjs-2";
// import { Button, Card, CardHeader, CardBody, NavItem, NavLink, Nav, Progress, Table, Container, Row, Col } from "reactstrap";
// import classnames from "classnames";
// import { chartOptions, chartExample1, chartExample2 } from "variables/charts.js";
// import Header from "components/Headers/Header.js";

// class Index extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       activeNav: 1,
//       chartExample1Data: "data1",
//       chartData: {},
//       appareillageData: {}
//     };
//   }

//   componentDidMount() {
//     this.fetchData();
//   }

//   // fetchData = async () => {
//   //   const token = localStorage.getItem('token').trim().replace('JWT ', '');
//   //   try {
//   //     const facturesResponse = await axios.get('https://app.vtalys.fr/api/factures', {
//   //       headers: { Authorization: `Bearer ${token}` }
//   //     });
//   //     const facturesData = this.processFacturesData(facturesResponse.data);
//   //     this.setState({ chartData: facturesData });

//   //     // Fetching clients data for appareillage
//   //     const clientsResponse = await axios.get('https://app.vtalys.fr/api/clients', {
//   //       headers: { Authorization: `Bearer ${token}` }
//   //     });
//   //     const appareillageData = this.processAppareillageData(clientsResponse.data);
//   //     this.setState({ appareillageData });
//   //   } catch (error) {
//   //     console.error('Erreur lors de la récupération des factures:', error);
//   //   }
//   // };
//   fetchData = async () => {
//     const token = localStorage.getItem('token').trim().replace('JWT ', '');
//     try {
//       const facturesResponse = await axios.get('https://app.vtalys.fr/api/factures', {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       const facturesData = this.processFacturesData(facturesResponse.data);
//       this.setState({ chartData: facturesData });
  
//       const clientsResponse = await axios.get('https://app.vtalys.fr/api/clients', {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       const appareillageData = this.processAppareillageData(clientsResponse.data);
//       this.setState({ appareillageData });
//     } catch (error) {
//       console.error('Erreur lors de la récupération des factures:', error);
//     }
//   };
  

//   // processFacturesData = (factures) => {
//   //   let totals = {};
//   //   factures.forEach(facture => {
//   //     const mois = new Date(facture.dateFacture).getMonth() + 1;
//   //     if (!totals[mois]) {
//   //       totals[mois] = 0;
//   //     }
//   //     totals[mois] += facture.totalGeneral;
//   //   });

//   //   return this.prepareChartData(totals);
//   // };

//   // processAppareillageData = (clients) => {
//   //   let totals = {};
//   //   clients.forEach(client => {
//   //     if (client.statut === "Appareillé") {
//   //       const mois = new Date(client.dateAppareillage).getMonth() + 1;
//   //       totals[mois] = (totals[mois] || 0) + 1;
//   //     }
//   //   });

//   //   return this.prepareChartData(totals);
//   // };

//   processFacturesData = (factures) => {
//     let totals = {};
//     factures.forEach(facture => {
//       const mois = new Date(facture.dateFacture).getMonth() + 1;
//       totals[mois] = (totals[mois] || 0) + facture.totalGeneral;
//     });
//     return this.prepareChartData(totals, "factures");
//   };
  
//   processAppareillageData = (clients) => {
//     let totals = {};
//     clients.forEach(client => {
//       if (client.statut === "Appareillé") {
//         const mois = new Date(client.dateAppareillage).getMonth() + 1;
//         totals[mois] = (totals[mois] || 0) + 1;
//       }
//     });
//     return this.prepareChartData(totals, "appareillages");
//   };
  

//   prepareChartData = (totals, type) => {
//     const sortedMonths = Object.keys(totals).sort((a, b) => a - b);
//     const labels = sortedMonths.map(mois =>
//       new Date(0, mois - 1).toLocaleString('default', { month: 'long' })
//     );
//     const data = sortedMonths.map(mois => totals[mois]);
//     const label = type === "factures" ? 'Chiffre d\'affaires' : 'Appareillages';
//     const borderColor = type === "factures" ? 'rgb(75, 192, 192)' : 'rgb(255, 99, 132)'; 
//     const backgroundColor = type === "factures" ? 'rgba(75, 192, 192, 0.5)' : 'rgba(255, 99, 132, 0.5)';
//     return {
//       labels,
//       datasets: [{
//         label: label,
//         data,
//         fill: false,
//         borderColor: borderColor,
//         backgroundColor: backgroundColor,
//         tension: 0.1
//       }]
//     };
//   };

//   toggleNavs = (e, index) => {
//     e.preventDefault();
//     this.setState({
//       activeNav: index,
//       chartExample1Data: this.state.chartExample1Data === "data1" ? "data2" : "data1"
//     });
//   };

//   render() {
//     return (
//       <>
//         <Header />
//         <Container className="mt--7" fluid>
//           <Row>
//             <Col className="mb-5 mb-xl-0" xl="8">
//               <Card className="bg-gradient-default shadow">
//                 <CardHeader className="bg-transparent">
//                   <Row className="align-items-center">
//                     <div className="col">
//                       <h2 className="text-white mb-0">Chiffre d'affaires</h2>
//                     </div>
//                   </Row>
//                 </CardHeader>
//                 <CardBody>
//                   <div className="chart">
//                     <Line
//                       data={this.state.chartData}
//                       options={chartExample1.options}
//                     />
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//           <Col xl="4">
//               <Card className="shadow">
//                 <CardHeader className="bg-transparent">
//                   <Row className="align-items-center">
//                     <div className="col">
//                       <h6 className="text-uppercase text-muted ls-1 mb-1">
//                         Performance
//                       </h6>
//                       <h2 className="mb-0">Total Appareillage</h2>
//                     </div>
//                   </Row>
//                 </CardHeader>
//                 <CardBody>
//                   {/* Chart */}
//                   <div className="chart">
//                     <Bar
//                       //data={this.state.appareillageData}
//                       data={chartExample2.data}
//                       options={chartExample2.options}
//                       className="chart-canvas"
//                  id="chart-bars"
//                     />
//                   </div>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>
//           <Row className="mt-5">
//             <Col className="mb-5 mb-xl-0" xl="8">
//               <Card className="shadow">
//                 <CardHeader className="border-0">
//                   <Row className="align-items-center">
//                     <div className="col">
//                       <h3 className="mb-0">Page visits</h3>
//                     </div>
//                     <div className="col text-right">
//                       <Button
//                         color="primary"
//                         href="#pablo"
//                         onClick={e => e.preventDefault()}
//                         size="sm"
//                       >
//                         See all
//                       </Button>
//                     </div>
//                   </Row>
//                 </CardHeader>
//                 <Table className="align-items-center table-flush" responsive>
//                   <thead className="thead-light">
//                     <tr>
//                       <th scope="col">Page name</th>
//                       <th scope="col">Visitors</th>
//                       <th scope="col">Unique users</th>
//                       <th scope="col">Bounce rate</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <th scope="row">/argon/</th>
//                       <td>4,569</td>
//                       <td>340</td>
//                       <td>
//                         <i className="fas fa-arrow-up text-success mr-3" />{" "}
//                         46,53%
//                       </td>
//                     </tr>
//                     <tr>
//                       <th scope="row">/argon/index.html</th>
//                       <td>3,985</td>
//                       <td>319</td>
//                       <td>
//                         <i className="fas fa-arrow-down text-warning mr-3" />{" "}
//                         46,53%
//                       </td>
//                     </tr>
//                     <tr>
//                       <th scope="row">/argon/charts.html</th>
//                       <td>3,513</td>
//                       <td>294</td>
//                       <td>
//                         <i className="fas fa-arrow-down text-warning mr-3" />{" "}
//                         36,49%
//                       </td>
//                     </tr>
//                     <tr>
//                       <th scope="row">/argon/tables.html</th>
//                       <td>2,050</td>
//                       <td>147</td>
//                       <td>
//                         <i className="fas fa-arrow-up text-success mr-3" />{" "}
//                         50,87%
//                       </td>
//                     </tr>
//                     <tr>
//                       <th scope="row">/argon/profile.html</th>
//                       <td>1,795</td>
//                       <td>190</td>
//                       <td>
//                         <i className="fas fa-arrow-down text-danger mr-3" />{" "}
//                         46,53%
//                       </td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               </Card>
//             </Col>
//             <Col xl="4">
//               <Card className="shadow">
//                 <CardHeader className="border-0">
//                   <Row className="align-items-center">
//                     <div className="col">
//                       <h3 className="mb-0">Origine Clients</h3>
//                     </div>
//                     <div className="col text-right">
//                       <Button
//                         color="primary"
//                         href="#pablo"
//                         onClick={e => e.preventDefault()}
//                         size="sm"
//                       >
//                         See all
//                       </Button>
//                     </div>
//                   </Row>
//                 </CardHeader>
//                 <Table className="align-items-center table-flush" responsive>
//                   <thead className="thead-light">
//                     <tr>
//                       <th scope="col">Referral</th>
//                       <th scope="col">Visitors</th>
//                       <th scope="col" />
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <th scope="row">Site</th>
//                       <td>1,480</td>
//                       <td>
//                         <div className="d-flex align-items-center">
//                           <span className="mr-2">60%</span>
//                           <div>
//                             <Progress
//                               max="100"
//                               value="60"
//                               barClassName="bg-gradient-danger"
//                             />
//                           </div>
//                         </div>
//                       </td>
//                     </tr>
//                     <tr>
//                       <th scope="row">Doctolib</th>
//                       <td>5,480</td>
//                       <td>
//                         <div className="d-flex align-items-center">
//                           <span className="mr-2">70%</span>
//                           <div>
//                             <Progress
//                               max="100"
//                               value="70"
//                               barClassName="bg-gradient-success"
//                             />
//                           </div>
//                         </div>
//                       </td>
//                     </tr>
//                     <tr>
//                       <th scope="row">Ouieqare</th>
//                       <td>4,807</td>
//                       <td>
//                         <div className="d-flex align-items-center">
//                           <span className="mr-2">80%</span>
//                           <div>
//                             <Progress max="100" value="80" />
//                           </div>
//                         </div>
//                       </td>
//                     </tr>
//                     <tr>
//                       <th scope="row">Instagram</th>
//                       <td>3,678</td>
//                       <td>
//                         <div className="d-flex align-items-center">
//                           <span className="mr-2">75%</span>
//                           <div>
//                             <Progress
//                               max="100"
//                               value="75"
//                               barClassName="bg-gradient-info"
//                             />
//                           </div>
//                         </div>
//                       </td>
//                     </tr>
//                     <tr>
//                       <th scope="row">twitter</th>
//                       <td>2,645</td>
//                       <td>
//                         <div className="d-flex align-items-center">
//                           <span className="mr-2">30%</span>
//                           <div>
//                             <Progress
//                               max="100"
//                               value="30"
//                               barClassName="bg-gradient-warning"
//                             />
//                           </div>
//                         </div>
//                       </td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </>
//     );
//   }
// }

// export default Index;


import React from "react";
import axios from 'axios';
import { Line, Bar } from "react-chartjs-2";
import { Button, Card, CardHeader, CardBody, NavItem, NavLink, Nav, Progress, Table, Container, Row, Col } from "reactstrap";
import classnames from "classnames";
import { chartOptions, chartExample1, chartExample2 } from "variables/charts.js";
import Header from "components/Headers/Header.js";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      chartData: {},
      appareillageData: {}
    };
  }

  // componentDidMount() {
  //   const token = localStorage.getItem('token');
  //   if (!token) {
  //     // Rediriger vers la page de connexion si le token n'est pas présent
  //     this.props.history.push('/auth/login');
  //   } else {
  //     // Si le token est présent, continuer à charger les données
  //     this.fetchData();
  //   }
  // }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const queryToken = query.get('token');
  
    console.log('URL actuelle :', window.location.href); // Vérifie l'URL actuelle
  
    if (queryToken) {
      console.log('Token reçu depuis l\'URL:', queryToken); // Vérifie que le token est bien extrait de l'URL
      localStorage.setItem('token', queryToken);
  
      // Simule le stockage d'un utilisateur (à remplacer par des données réelles)
      localStorage.setItem('user', JSON.stringify({ name: 'Utilisateur' }));
  
      this.props.history.replace('/admin/agenda'); // Utilise replace pour éviter d'avoir l'URL avec le token dans l'historique
    } else if (!localStorage.getItem('token')) {
      console.log('Redirection vers login, aucun token trouvé');
      this.props.history.push('/auth/login');
    } else {
      console.log('Token déjà présent dans localStorage:', localStorage.getItem('token'));
      this.fetchData(); // Continue avec la logique de chargement de données
    }
  }
  

  fetchData = async () => {
    const token = localStorage.getItem('token').trim().replace('JWT ', '');
    try {
      const facturesResponse = await axios.get('https://app.vtalys.fr/api/factures', {
        headers: { Authorization: `Bearer ${token}` }
      });
      const facturesData = this.processFacturesData(facturesResponse.data);
      this.setState({ chartData: facturesData });

      // Fetching clients data for appareillage
      const clientsResponse = await axios.get('https://app.vtalys.fr/api/clients', {
        headers: { Authorization: `Bearer ${token}` }
      });
      const appareillageData = this.processAppareillageData(clientsResponse.data);
      this.setState({ appareillageData });
    } catch (error) {
      console.error('Erreur lors de la récupération des factures:', error);
      // if (error.response && error.response.status === 401) {
      //   // Si l'erreur est une autorisation refusée, redirige vers la connexion
      //   localStorage.removeItem('token'); // Retire le token invalide
      //   this.props.history.push('/auth/login');
      // }
    }
  };

  processFacturesData = (factures) => {
    let totals = {};
    factures.forEach(facture => {
      const mois = new Date(facture.dateFacture).getMonth() + 1;
      if (!totals[mois]) {
        totals[mois] = 0;
      }
      totals[mois] += facture.totalGeneral;
    });

    return this.prepareChartData(totals);
  };

  processAppareillageData = (clients) => {
    let totals = {};
    clients.forEach(client => {
      if (client.statut === "Appareillé") {
        const mois = new Date(client.dateAppareillage).getMonth() + 1;
        totals[mois] = (totals[mois] || 0) + 1;
      }
    });

    return this.prepareChartData(totals);
  };

  prepareChartData = (totals) => {
    const sortedMonths = Object.keys(totals).sort((a, b) => a - b);
    const labels = sortedMonths.map(mois =>
      new Date(0, mois - 1).toLocaleString('default', { month: 'long' })
    );
    const data = sortedMonths.map(mois => totals[mois]);

    return {
      labels,
      datasets: [{
        label: 'Total',
        data,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data: this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
  };

  // useEffect(() => {
  //   const query = new URLSearchParams(window.location.search);
  //   const token = query.get('token');
  //   if (token) {
  //     localStorage.setItem('token', token); // Stocker le token
  //     props.history.push('/admin/index'); // Rediriger vers la page d'accueil
  //   }
  // }, [props.history]);
  
  

  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="text-white mb-0">Chiffre d'affaires</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={this.state.chartData}
                      options={chartExample1.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Total Appareillage</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Bar
                      data={this.state.appareillageData}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Page visits</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Page name</th>
                      <th scope="col">Visitors</th>
                      <th scope="col">Unique users</th>
                      <th scope="col">Bounce rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">/argon/</th>
                      <td>4,569</td>
                      <td>340</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/index.html</th>
                      <td>3,985</td>
                      <td>319</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/charts.html</th>
                      <td>3,513</td>
                      <td>294</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                        36,49%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/tables.html</th>
                      <td>2,050</td>
                      <td>147</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />{" "}
                        50,87%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/profile.html</th>
                      <td>1,795</td>
                      <td>190</td>
                      <td>
                        <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Origine Clients</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Referral</th>
                      <th scope="col">Visitors</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Site</th>
                      <td>1,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">60%</span>
                          <div>
                            <Progress
                              max="100"
                              value="60"
                              barClassName="bg-gradient-danger"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Doctolib</th>
                      <td>5,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">70%</span>
                          <div>
                            <Progress
                              max="100"
                              value="70"
                              barClassName="bg-gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Ouieqare</th>
                      <td>4,807</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">80%</span>
                          <div>
                            <Progress max="100" value="80" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Instagram</th>
                      <td>3,678</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">75%</span>
                          <div>
                            <Progress
                              max="100"
                              value="75"
                              barClassName="bg-gradient-info"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">twitter</th>
                      <td>2,645</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">30%</span>
                          <div>
                            <Progress
                              max="100"
                              value="30"
                              barClassName="bg-gradient-warning"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;