import React from "react";
import classnames from "classnames";
import {
    Button,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Card,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane
} from "reactstrap";
import HeaderAb from "components/Headers/HeaderAb.js";

class Abonnement extends React.Component {
    render() {
        // Style pour uniformiser l'alignement des icônes et des textes
        const listItemStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Alignement à gauche
            height: '60px', // Hauteur plus grande pour inclure l'espace vertical
            width: '100%',  // Utilise toute la largeur
            marginBottom: '15px'
        };
        const iconStyle = {
            marginRight: '15px', // Espace entre l'icône et le texte
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '30px', // Largeur fixe pour les icônes
            height: '30px',
        };
        return (
            <>
                <HeaderAb />

                <Container className="mt--7" fluid>
                    <Row>
                        {/* Pack Starter */}
                        <Col lg="4" md="6" className="mb-5">
                            <Card className="card-pricing bg-gradient-success border-0 text-center">
                                <CardHeader className="bg-transparent">
                                    <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                                        Pack Starter
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="display-2 text-white">99€</div>
                                    <span className="text-white">par mois</span>
                                    <ul className="list-unstyled my-4">
                                        <li style={listItemStyle}>
                                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                                                <i className="ni ni-check-bold" />
                                            </div>
                                            <span className="pl-2 text-sm text-white">1 compte utilisateur</span>
                                        </li>
                                        <li style={listItemStyle}>
                                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                                                <i className="ni ni-settings-gear-65" />
                                            </div>
                                            <span className="pl-2 text-sm text-white">Accès complet aux fonctionnalités de base</span>
                                        </li>
                                        <li style={listItemStyle}>
                                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                                                <i className="ni ni-support-16" />
                                            </div>
                                            <span className="pl-2 text-sm text-white">Support technique standard</span>
                                        </li>
                                    </ul>
                                    <Button className="mb-3" color="primary">
                                        Souscrire
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>

                        {/* Pack Business */}
                        <Col lg="4" md="6" className="mb-5">
                            <Card className="card-pricing bg-gradient-warning border-0 text-center">
                                <CardHeader className="bg-transparent">
                                    <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                                        Pack Business
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="display-2 text-white">149€</div>
                                    <span className="text-white">par mois</span>
                                    <ul className="list-unstyled my-4">
                                        <li style={listItemStyle}>
                                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                                                <i className="ni ni-world" />
                                            </div>
                                            <span className="pl-2 text-sm text-white">5 comptes utilisateurs</span>
                                        </li>
                                        <li style={listItemStyle}>
                                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                                                <i className="ni ni-single-02" />
                                            </div>
                                            <span className="pl-2 text-sm text-white">Accès à toutes les fonctionnalités</span>
                                        </li>
                                        <li style={listItemStyle}>
                                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                                                <i className="ni ni-trophy" />
                                            </div>
                                            <span className="pl-2 text-sm text-white">Support prioritaire</span>
                                        </li>
                                    </ul>
                                    <Button className="mb-3" color="warning">
                                        Souscrire
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>

                        {/* Pack Ultimate */}
                        <Col lg="4" md="6" className="mb-5">
                            <Card className="card-pricing bg-gradient-info border-0 text-center">
                                <CardHeader className="bg-transparent">
                                    <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                                        Pack Ultimate
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="display-2 text-white">199€</div>
                                    <span className="text-white">par mois</span>
                                    <ul className="list-unstyled my-4">
                                        <li style={listItemStyle}>
                                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                                                <i className="ni ni-satisfied" />
                                            </div>
                                            <span className="pl-2 text-sm text-white">10 comptes utilisateurs</span>
                                        </li>
                                        <li style={listItemStyle}>
                                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                                                <i className="ni ni-bullet-list-67" />
                                            </div>
                                            <span className="pl-2 text-sm text-white">Accès illimité à toutes les fonctionnalités</span>
                                        </li>
                                        <li style={listItemStyle}>
                                            <div className="icon icon-xs icon-shape bg-white shadow rounded-circle">
                                                <i className="ni ni-bell-55" />
                                            </div>
                                            <span className="pl-2 text-sm text-white">Support premium</span>
                                        </li>
                                    </ul>
                                    <Button className="mb-3" color="info">
                                        Souscrire
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Abonnement;