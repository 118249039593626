import React, { useState } from "react";
import axios from 'axios';



// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useHistory } from "react-router-dom";

const Profile = () => {

    // const user = JSON.parse(localStorage.getItem("user"));
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [image, setImage] = useState(user.image || "assets/img/theme/sketch.jpg");
    const [editMode, setEditMode] = useState(false);
    const history = useHistory();

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('userID', user._id);

            try {
                const response = await axios.post('/api/users/upload-image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                const { data } = response;
                if (data.success) {
                    setImage(URL.createObjectURL(file));
                    user.image = data.imagePath;  // Update user image path
                    localStorage.setItem("user", JSON.stringify(user));
                }
            } catch (err) {
                console.error("Error uploading image:", err);
                alert("Le service est temporairement indisponible. Veuillez réessayer plus tard.");
            }
        }
    };

    const handleSaveChanges = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post('/api/users/edit', {
                userID: user._id,
                name: user.name,
                email: user.email,
                telephone: user.telephone,
                adresse: user.adresse,
                ville: user.ville,
                codePostal: user.codePostal,
                //prescripteur: user.prescripteur,
                numeroAdeli: user.numeroAdeli,
                image: user.image
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.success) {
                setEditMode(false);
                localStorage.setItem("user", JSON.stringify(user));
            } else {
                alert("Erreur lors de la sauvegarde des modifications.");
            }
        } catch (err) {
            console.error("Erreur lors de l'enregistrement des données:", err);
            alert("Une erreur est survenue, veuillez réessayer.");
        }
    };



    return (
        <>
            <UserHeader />
            {/* Page content */}

            <Container className="mt--7" fluid>
                <Row>

                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                        <Card className="card-profile shadow">
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">
                                        <a href="#pablo" onClick={e => {
                                            e.preventDefault()
                                            document.getElementById('imageInput').click();
                                        }}>
                                            <img
                                                alt="..."
                                                className="rounded-circle"
                                                src={user.image ? `/${user.image}` : require("assets/img/theme/sketch.jpg").default}
                                            />

                                        </a>
                                        <input
                                            type="file"
                                            id="imageInput"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange}
                                            accept="image/*"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between">
                                    {/* <Button
                                        className="mr-4"
                                        color="info"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                        size="sm"
                                    >
                                        Connect
                                    </Button> */}
                                    {/* <Button
                                        className="float-right"
                                        color="default"
                                        href="#pablo"
                                        onClick={e => e.preventDefault()}
                                        size="sm"
                                    >
                                        Message
                                    </Button> */}
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0 pt-md-4">
                                <Row>
                                    <div className="col">
                                        <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                            <div>
                                                <span className="heading"></span>
                                                <span className="description"></span>
                                            </div>
                                            <div>
                                                <span className="heading"></span>
                                                <span className="description"></span>
                                            </div>
                                            <div>
                                                <span className="heading"></span>
                                                <span className="description"></span>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <div className="text-center">
                                    <h3>
                                        {user.name}
                                        <span className="font-weight-light"></span>
                                    </h3>
                                    <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2" />
                                        {user.ville}
                                    </div>
                                    {/* <div className="h5 mt-4">
                                        <i className="ni business_briefcase-24 mr-2"/>
                                        Solution Manager - Creative Tim Officer
                                    </div>
                                    <div>
                                        <i className="ni education_hat mr-2"/>
                                        University of Computer Science
                                    </div> */}
                                    <hr className="my-4" />
                                    {/* <p>
                                        Ryan — the name taken by Melbourne-raised, Brooklyn-based
                                        Nick Murphy — writes, performs and records all of his own
                                        music.
                                    </p>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                        Show more
                                    </a> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Mon Compte</h3>
                                    </Col>
                                    {/* <Col className="text-right" xs="4">
                                        <Button
                                            color="primary"
                                            href="#pablo"
                                            onClick={() => history.push('/admin/edit-profile')}
                                            size="sm"
                                        >
                                            Paramètres
                                        </Button>
                                    </Col> */}
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Informations du compte
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Nom du Compte
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={user.name}
                                                        id="input-username"
                                                        placeholder="Nom du Compte"
                                                        type="text"
                                                        disabled={!editMode}
                                                        onChange={e => setUser({ ...user, name: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Email
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        defaultValue={user.email}
                                                        type="email"
                                                        disabled={!editMode}
                                                        onChange={e => setUser({ ...user, name: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/*<Row>*/}
                                        {/*    <Col lg="6">*/}
                                        {/*        <FormGroup>*/}
                                        {/*            <label*/}
                                        {/*                className="form-control-label"*/}
                                        {/*                htmlFor="input-first-name"*/}
                                        {/*            >*/}
                                        {/*                First name*/}
                                        {/*            </label>*/}
                                        {/*            <Input*/}
                                        {/*                className="form-control-alternative"*/}
                                        {/*                defaultValue="Lucky"*/}
                                        {/*                id="input-first-name"*/}
                                        {/*                placeholder="First name"*/}
                                        {/*                type="text"*/}
                                        {/*            />*/}
                                        {/*        </FormGroup>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col lg="6">*/}
                                        {/*        <FormGroup>*/}
                                        {/*            <label*/}
                                        {/*                className="form-control-label"*/}
                                        {/*                htmlFor="input-last-name"*/}
                                        {/*            >*/}
                                        {/*                Last name*/}
                                        {/*            </label>*/}
                                        {/*            <Input*/}
                                        {/*                className="form-control-alternative"*/}
                                        {/*                defaultValue="Jesse"*/}
                                        {/*                id="input-last-name"*/}
                                        {/*                placeholder="Last name"*/}
                                        {/*                type="text"*/}
                                        {/*            />*/}
                                        {/*        </FormGroup>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                    </div>
                                    <hr className="my-4" />
                                    {/* Address */}
                                    <h6 className="heading-small text-muted mb-4">
                                        Informations complémentaires
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Addresse
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={user.adresse}
                                                        id="input-address"
                                                        placeholder="Addresse"
                                                        type="text"
                                                        disabled={!editMode}
                                                        onChange={e => setUser({ ...user, name: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        Ville
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={user.ville}
                                                        id="input-city"
                                                        placeholder="Ville"
                                                        type="text"
                                                        disabled={!editMode}
                                                        onChange={e => setUser({ ...user, name: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            {/* <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Country
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue="United States"
                                                        id="input-country"
                                                        placeholder="Country"
                                                        type="text"
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col> */}
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Code Postal
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={user.codePostal}
                                                        id="input-postal-code"
                                                        placeholder="Code Postal"
                                                        type="number"
                                                        disabled={!editMode}
                                                        onChange={e => setUser({ ...user, name: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Téléphone
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={user.telephone}
                                                        id="input-postal-code"
                                                        placeholder="Téléphone"
                                                        type="number"
                                                        disabled={!editMode}
                                                        onChange={e => setUser({ ...user, name: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Numéro ADELI
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={user.numeroAdeli}
                                                        id="input-postal-code"
                                                        placeholder="Numéro ADELI"
                                                        type="number"
                                                        disabled={!editMode}
                                                        onChange={e => setUser({ ...user, name: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            {/* <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        Prescripteur
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue={user.prescripteur}
                                                        id="input-city"
                                                        placeholder="Prescripteur"
                                                        type="text"
                                                        disabled={!editMode}
                                                        onChange={e => setUser({...user, name: e.target.value})}
                                                    />
                                                </FormGroup>
                                            </Col> */}
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    {/* Description */}
                                    {/* <h6 className="heading-small text-muted mb-4">About me (placeholder)</h6>
                                    <div className="pl-lg-4">
                                        <FormGroup>
                                            <label>About Me</label>
                                            <Input
                                                className="form-control-alternative"
                                                placeholder="A few words about you ..."
                                                rows="4"
                                                defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                          Open Source."
                                                type="textarea"
                                                disabled
                                            />
                                        </FormGroup>
                                    </div> */}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Profile;
