import React from "react";
import classnames from "classnames";
import ImageDropzone from './ImageDropzone';  // Ajuste le chemin selon la structure de ton projet

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

class StockPage extends React.Component {
  state = {
    filteredStock: [],
    selectedMarque: "Toutes",
    modalOpen: false,
    isEditing: false,
    newProduct: {
      name: "",
      marque: "",
      model: "",
      lpp: "",
      montantHT: "",
      code: 0,
      quantity: 0,
      price: "",
      oreilleGauche: false,
      oreilleDroite: false,
      description: "",
      imageUrl: "",
      successMessage: ""
    },
  };

  componentDidMount() {
    // Charger les données du stock depuis le backend lors du montage du composant
    this.loadStockData();
  }

  loadStockData = () => {
    fetch('/api/produits')
      .then(response => response.json())
      .then(data => this.setState({ filteredStock: data }))
      .catch(error => console.error('Erreur lors du chargement du stock:', error));
  }
  editProduct = (item) => {
    console.log("Editing product:", item); // Afficher les détails du produit
    this.setState({
      newProduct: { ...item },
      modalOpen: true,
      isEditing: true
    });
  };



  // toggleModal = () => {
  //   this.setState({ modalOpen: !this.state.modalOpen });
  // };
  toggleModal = () => {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
      isEditing: false, // Réinitialisez isEditing lorsque la modale est fermée
      newProduct: { // Réinitialisez newProduct pour un nouvel ajout
        name: "",
        marque: "",
        model: "",
        lpp: "",
        montantHT: "",
        code: 0,
        quantity: 0,
        price: "",
        oreilleGauche: false,
        oreilleDroite: false,
        description: "",
        imageUrl: ""
      }
    }));
  };



  // handleInputChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   const val = type === "checkbox" ? checked : value;
  //   this.setState({ newProduct: { ...this.state.newProduct, [name]: val } });
  // };
  handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let val = value;

    if (type === "checkbox") {
      val = checked;
    } else if (type === "number") {
      val = Number(value); // Convertir en nombre les entrées pour les champs numériques
    }

    this.setState({ newProduct: { ...this.state.newProduct, [name]: val } });
  };


  handleImageChange = (e) => {
    this.setState({ newProduct: { ...this.state.newProduct, image: e.target.files[0] } });
  };


  // validateForm = () => {
  //   const { name, marque, price, quantity } = this.state.newProduct;
  //   return name && marque && price && quantity; // Ensure all required fields are filled
  // };
  // validateForm = () => {
  //   const { name, marque, model, code, LPP, price, quantity } = this.state.newProduct;
  //   // Assurez-vous que les champs numériques sont non seulement remplis mais aussi valides
  //   const isNumericFieldsValid = code > 0 && LPP > 0 && price > 0 && quantity > 0;

  //   // Vérifiez que les champs de texte ne sont ni vides ni uniquement composés d'espaces blancs
  //   const isTextFieldsValid = name.trim() !== "" && marque.trim() !== "" && model.trim() !== "";

  //   return isNumericFieldsValid && isTextFieldsValid;
  // };


// Partie de addOrUpdateProduct pour inclure l'ID dans la requête de mise à jour
addOrUpdateProduct = () => {
  // if (!this.validateForm()) {
  //   alert('Please fill all required fields.');
  //   return;
  // }

  const formData = new FormData();
  Object.keys(this.state.newProduct).forEach(key => {
    if (key === 'image') {
      if (this.state.newProduct[key]) {
        formData.append('image', this.state.newProduct[key], this.state.newProduct[key].name);
      }
    } else {
      formData.append(key, this.state.newProduct[key]);
    }
  });

  // Modification pour inclure l'ID lors de la mise à jour
  const url = this.state.isEditing ? `/api/produits/update/${this.state.newProduct._id}` : '/api/produits/add';
  fetch(url, {
    method: 'POST',
    body: formData,
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Server responded with ' + response.status);
    }
    return response.json();
  })
  .then(data => {
    let message = this.state.isEditing ? "Produit modifié avec succès." : "Produit ajouté avec succès.";
    this.setState(prevState => ({
      filteredStock: this.state.isEditing ? prevState.filteredStock.map(item => item._id === data._id ? data : item) : [...prevState.filteredStock, data],
      modalOpen: false,
      newProduct: {
        name: "",
        marque: "",
        model: "",
        lpp: "",
        montantHT: "",
        code: 0,
        quantity: 0,
        price: "",
        oreilleGauche: false,
        oreilleDroite: false,
        description: "",
        imageUrl: ""
      },
      isEditing: false,
      successMessage: message
    }));
  })
  .catch(error => {
    console.error("Erreur lors de l'ajout/mise à jour du produit:", error);
    alert('Error: ' + error.message);
  });
};

  // Méthode addOrUpdateProduct dans votre composant StockPage
  // addOrUpdateProduct = () => {
  //   // if (!this.validateForm()) {
  //   //   alert('Please fill all required fields.');
  //   //   return;
  //   // }

  //   const formData = new FormData();
  //   Object.keys(this.state.newProduct).forEach(key => {
  //     if (key === 'image') {
  //       if (this.state.newProduct[key]) {
  //         formData.append('image', this.state.newProduct[key], this.state.newProduct[key].name);
  //       }
  //     } else {
  //       formData.append(key, this.state.newProduct[key]);
  //     }
  //   });

  //   const endpoint = this.state.isEditing ? 'update' : 'add';
  //   fetch(`/api/produits/${endpoint}`, {
  //     method: 'POST',
  //     body: formData,
  //   })
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Server responded with ' + response.status);
  //       }
  //       return response.json();
  //     })
  //     .then(data => {
  //       let message = this.state.isEditing ? "Produit modifié avec succès." : "Produit ajouté avec succès.";
  //       this.setState(prevState => ({
  //         filteredStock: this.state.isEditing ? prevState.filteredStock.map(item => item._id === data._id ? data : item) : [...prevState.filteredStock, data],
  //         modalOpen: false,
  //         newProduct: {
  //           name: "",
  //           marque: "",
  //           model: "",
  //           lpp: "",
  //           montantHT: "",
  //           code: 0,
  //           quantity: 0,
  //           price: "",
  //           oreilleGauche: false,
  //           oreilleDroite: false,
  //           description: "",
  //           imageUrl: ""
  //         },
  //         isEditing: false,
  //         successMessage: message
  //       }));
  //     })
  //     .catch(error => {
  //       console.error('Erreur lors de l\'ajout/mise à jour du produit:', error);
  //       alert('Error: ' + error.message);
  //     });
  // };


  // addOrUpdateProduct = () => {
  //   if (!this.validateForm()) {
  //     alert('Please fill all required fields.'); // Simple validation feedback
  //     return;
  //   }

  //   const formData = new FormData();
  //   Object.keys(this.state.newProduct).forEach(key => {
  //     if (key === 'image') {
  //       if (this.state.newProduct[key]) {
  //         formData.append('image', this.state.newProduct[key], this.state.newProduct[key].name);
  //       }
  //     } else {
  //       formData.append(key, this.state.newProduct[key]);
  //     }
  //   });

  //   const url = this.state.isEditing ? '/api/produits/update' : '/api/produits/add';
  //   fetch(url, {
  //     method: 'POST',
  //     body: formData,
  //   })
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Server responded with ' + response.status);
  //       }
  //       return response.json(); // Assurez-vous que la réponse est du JSON
  //     })
  //     .then(data => {
  //       let message = this.state.isEditing ? "Produit modifié avec succès." : "Produit ajouté avec succès.";
  //       this.setState(prevState => ({
  //         filteredStock: this.state.isEditing ? prevState.filteredStock.map(item => item._id === data._id ? data : item) : [...prevState.filteredStock, data],
  //         modalOpen: false,
  //         newProduct: {
  //           name: "",
  //           marque: "",
  //           code: 0,
  //           quantity: 0,
  //           price: "",
  //           oreilleGauche: false,
  //           oreilleDroite: false,
  //           description: "",
  //           imageUrl: ""
  //         },
  //         isEditing: false,
  //         successMessage: message
  //       }));
  //     })
  //     .catch(error => {
  //       console.error('Erreur lors de l\'ajout/mise à jour du produit:', error);
  //       alert('Error: ' + error.message);
  //     });
  // };


  filterByMarque = (marque) => {
    if (marque === "Toutes") {
      this.loadStockData();
    } else {
      fetch(`/api/produits?marque=${marque}`)
        .then(response => response.json())
        .then(data => this.setState({ filteredStock: data }))
        .catch(error => console.error('Erreur lors du filtrage du stock:', error));
    }
  };

  // deleteProduct = (productId) => {
  //   fetch(`/api/produits/delete/${productId}`, { method: 'DELETE' })
  //     .then(response => response.json())
  //     .then(() => {
  //       this.setState(prevState => ({
  //         filteredStock: prevState.filteredStock.filter(item => item._id !== productId),
  //         successMessage: "Produit supprimé avec succès."
  //       }));
  //     })
  //     .catch(error => console.error('Erreur lors de la suppression du produit:', error));
  // };

  deleteProduct = (productId) => {
    fetch(`/api/produits/delete/${productId}`, { method: 'DELETE' })
      .then(response => {
        if (!response.ok) throw new Error('Échec de la suppression du produit');
        return response.json();
      })
      .then(data => {
        this.setState(prevState => ({
          filteredStock: prevState.filteredStock.filter(item => item._id !== productId),
          successMessage: data.message
        }));
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du produit:', error);
        this.setState({ successMessage: "Erreur lors de la suppression du produit." });
      });
  };


  render() {
    return (
      <>
        <div style={{ paddingTop: '20px', background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)' }}>
          <Container className="mt-5" style={{ paddingBottom: '50px' }}>
            <div style={{ textAlign: 'left', marginBottom: '20px' }}>
              {/* <Button color="link" style={{ color: '#FF5A5F', textDecoration: 'none' }}>
                &lt; Retour
              </Button> */}
              <h2 style={{ color: '#FF5A5F', display: 'inline-block', marginLeft: '20px' }}></h2>
            </div>
            <Card className="card-calendar">
              <CardHeader style={{ paddingTop: '20px', background: '#fff', borderBottom: '1px solid #e3e3e3' }}>
                {this.state.successMessage && <div className="alert alert-success">{this.state.successMessage}</div>}
                <Row style={{ marginTop: '10px' }} className="align-items-center py-2">
                  <Col lg="9" md="8" sm="6">
                    <h6 className="h2 text-dark d-inline-block mb-0 mr-1">
                      Stock des Matériels
                    </h6>

                  </Col>
                  <Col lg="3" md="4" sm="6" className="text-lg-right">
                    <Button color="success" onClick={this.toggleModal} style={{ marginTop: '20px' }}>
                      Ajouter un produit
                    </Button>
                  </Col>
                  <Col lg="6" className="text-lg-left">
                    <FormGroup>
                      <Label for="marqueSelect" className="text-dark" style={{ textAlign: 'left' }}> {/* Style inline pour assurer l'alignement à gauche */}
                        Filtrer par Marque:
                      </Label>
                      <Input
                        type="select"
                        id="marqueSelect"
                        value={this.state.selectedMarque}
                        onChange={(e) => this.filterByMarque(e.target.value)}
                      >
                        <option value="Toutes">Toutes</option>
                        <option value="Marque 1">Marque 1</option>
                        <option value="Marque 2">Marque 2</option>
                        <option value="Marque 3">Marque 3</option>
                        {/* Ajoutez d'autres marques ici */}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>


              <CardBody className="p-0" style={{ margin: '20px' }}>
                <Row>
                  {this.state.filteredStock.map((item) => (
                    <Col lg="3" md="6" key={item._id}>
                      <Card className="mb-4" style={{ border: 'none', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                        <CardBody style={{ textAlign: 'center', position: 'relative' }}>
                          {/* Afficher le logo du site à la place de l'image du produit */}
                          <img
                            src={require("assets/img/brand/logo.png").default}
                            alt={item.name}
                            style={{ maxHeight: '100px', marginBottom: '15px' }} // Réduction de la taille de l'image
                          />

                          {/* Afficher la quantité dans un cercle centré sur l'image */}
                          <div style={{
                            position: 'absolute',
                            top: '35%',  // Positionné encore plus haut
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontSize: '18px',
                            zIndex: 1
                          }}>
                            {item.quantity}
                          </div>

                          {/* Nom et Marque du produit */}
                          <div style={{ marginTop: '40px' }}> {/* Augmentation de la marge supérieure */}
                            <h5 className="card-title">{item.name}</h5>
                            <p className="card-text">{item.marque}</p>
                          </div>

                          <p className="card-text"><strong>{item.price}€</strong></p>
                          <Button color="primary" onClick={() => this.editProduct(item)}>Modifier</Button>
                          <Button color="danger" onClick={() => this.deleteProduct(item._id)}>
                            <i className="fa fa-trash"></i>
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </CardBody>


            </Card>
          </Container>
        </div >

        {/* Modal pour ajouter un produit */}
        < Modal isOpen={this.state.modalOpen} toggle={this.toggleModal} >
          <ModalHeader toggle={this.toggleModal}>
            {this.state.isEditing ? "Modifier un produit" : "Ajouter un nouveau produit"}
          </ModalHeader>

          <ModalBody>

            <FormGroup>
              <Label for="productImage">Image du produit</Label>
              {/* <Input
                type="file"
                id="productImage"
                name="imageUrl"
                onChange={this.handleImageChange}
              /> */}
              <ImageDropzone onFileSelected={(file) => this.handleImageChange({ target: { name: 'imageUrl', files: [file] } })} />
            </FormGroup>

            <FormGroup>
              <Label for="productName">Nom du produit</Label>
              <Input
                type="text"
                id="productName"
                name="name"
                value={this.state.newProduct.name}
                onChange={this.handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="productMarque">Marque</Label>
              <Input
                type="text"
                id="productMarque"
                name="marque"
                value={this.state.newProduct.marque}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="productMarque">Model</Label>
              <Input
                type="text"
                id="productMarque"
                name="model"
                value={this.state.newProduct.model}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="Code">Code Produit</Label>
              <Input
                type="text"
                id="Code"
                name="code"
                value={this.state.newProduct.code}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="CodeLpp">Code LPP</Label>
              <Input
                type="text"
                id="CodeLpp"
                name="lpp"
                value={this.state.newProduct.lpp}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="productPrice">Prix HT</Label>
              <Input
                type="text"
                id="productPrice"
                name="price"
                value={this.state.newProduct.price}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="productQuantity">Quantité</Label>
              <Input
                type="number"
                id="productQuantity"
                name="quantity"
                value={this.state.newProduct.quantity}
                onChange={this.handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="oreilleGauche"
                  checked={this.state.newProduct.oreilleGauche}
                  onChange={this.handleInputChange}
                />{' '}
                Oreille gauche
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="oreilleDroite"
                  checked={this.state.newProduct.oreilleDroite}
                  onChange={this.handleInputChange}
                />{' '}
                Oreille droite
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="productDescription">Descriptif</Label>
              <Input
                type="textarea"
                id="productDescription"
                name="description"
                value={this.state.newProduct.description}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addOrUpdateProduct}>
              OK
            </Button>{' '}
            <Button color="secondary" onClick={this.toggleModal}>
              Annuler
            </Button>
          </ModalFooter>
        </Modal >
      </>
    );
  }
}

export default StockPage;
