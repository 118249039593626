// const config = {
//     WS_BASE_URL: 'http://localhost:5100/api/',
//     DOMAIN_NAME: 'http://localhost:3000',
//     DEMO: true
// }

// export default config;
const config = {
    // WS_BASE_URL: 'https://ouieqare-crm-336f65ca3acc.herokuapp.com/api/',  // Remplacez par l'URL de votre application Heroku
    // DOMAIN_NAME: 'https://ouieqare-crm-336f65ca3acc.herokuapp.com',  // Remplacez par l'URL de votre application Heroku
    // DEMO: true
    WS_BASE_URL: 'https://app.vtalys.fr/api/',  // Remplacez par l'URL de votre application Heroku
    DOMAIN_NAME: 'https://app.vtalys.fr',  // Remplacez par l'URL de votre application Heroku
    DEMO: true
};

export default config;
