import React from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class Header extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      
      totalClients: localStorage.getItem('totalClients') || 0,
      totalAppareilles: 0,
      totalFactures: 0
    };
  }

  componentDidMount() {
    this.fetchCounts();
}

fetchCounts = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const headers = {
        'Authorization': `Bearer ${localStorage.getItem('token').trim().replace('JWT ', '')}`
      };

      console.log("Sending request to fetch counts with token:", token);

      const responses = await Promise.all([
        fetch('https://ouieqare-crm-336f65ca3acc.herokuapp.com/api/counts/total', { headers }),
        fetch('https://ouieqare-crm-336f65ca3acc.herokuapp.com/api/counts/appareilles', { headers }),
        fetch('https://ouieqare-crm-336f65ca3acc.herokuapp.com/api/counts/factures', { headers })
      ]);

      console.log("Responses received:", responses);

      if (responses.some(response => !response.ok)) {
        const errors = await Promise.all(responses.map(res => res.text()));
        throw new Error(`Failed to fetch counts: ${errors.join(', ')}`);
      }

      const data = await Promise.all(responses.map(res => {
        if (!res.ok) {
          throw new Error(`Failed to fetch counts: ${res.status} - ${res.statusText}`);
        }
        return res.json();
      }));

      console.log("Counts data:", data);


      this.setState({
        totalClients: data[0].totalClients || 0,
        totalAppareilles: data[1].totalAppareilles || 0,
        totalFactures: data[2].totalFactures || 0
      });
      console.log("Updated state:", this.state);
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
}


  render() {
    const { totalAppareilles, totalFactures } = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-8" style={{ background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Nombre Clients
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                          {this.state.totalClients}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Depuis le mois dernier</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col lg="6" xl="2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Nombre RDV
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            49,65%
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Depuis le mois dernier</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col> */}
                {/* <Col lg="6" xl="2">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            En Livraison
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                         2
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Depuis le mois dernier</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col> */}
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Appareillés
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{totalAppareilles}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "}
                        <span className="text-nowrap">Depuis hier</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Facturés
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{totalFactures}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "}
                        <span className="text-nowrap">Depuis hier</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Performance
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            49,65%
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Depuis le mois dernier</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;