import React, { useState } from "react";
import {
  Container
  } from "reactstrap";

const ClientDashboard = () => {



    return (
        <div style={{ paddingTop: '50px', paddingBottom: '30px', background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)' }}>
            <Container className="mt-5">


            </Container>
        </div>
    );
};

export default ClientDashboard;
