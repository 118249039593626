import React from "react";
import { Container, Row, NavItem, Nav } from "reactstrap";
import { NavLink } from "react-router-dom";  // Import from react-router-dom

class HeaderAb extends React.Component {
  render() {
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-8" style={{ background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)' }}>
          <Container fluid>
            <div className="header-body">
              <Nav className="nav-fill flex-column flex-md-row" pills role="tablist"
                style={{ maxWidth: '600px', width: '100%', margin: '0 auto 30px auto' }}>
                <NavItem>
                  <NavLink to="/admin/Abonnement" className="nav-link" activeClassName="active">

                    <i className="ni ni-badge text-green" style={{ paddingRight: '8px' }} />
                    Mon Abonnement
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/MesFactures" className="nav-link" activeClassName="active">
                    <i className="ni ni-briefcase-24 text-green" style={{ paddingRight: '8px' }} />
                    Mes Factures
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/MoyenDePaiement" className="nav-link" activeClassName="active">
                    <i className="ni ni-credit-card text-green" style={{ paddingRight: '8px' }} />
                    Moyen de paiement
                  </NavLink>
                </NavItem>
              </Nav>
              <Row></Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default HeaderAb;
