// import axios from 'axios';
// import config from "../config";

// // const https = require('https');
// //
// // const agent = new https.Agent({
// //     rejectUnauthorized: false,
// // });

// const instance = axios.create({
//     baseURL: config.WS_BASE_URL,
// });

// instance.interceptors.request.use(async (config) => {
//     const token = localStorage.getItem('token');
//     config.headers.Authorization = (token ? token : '');
//     config.headers.ContentType = 'application/json';
//     return config;
// });

// export const getAll = async () => (
//     await instance.post('users/all')
// );

// export const register = async (name, email, password) => {
//     console.log("Attempting to register to:", `${config.WS_BASE_URL}users/register`);
//     return await instance.post('/users/register', { name, email, password });
// };

// export const confirmRegister = async id => (
//     await instance.post(`users/confirm/${id}`)
// );

// export const forgotPassword = async email => (
//     await instance.post('users/forgotpassword', {email})
// );

// export const confirmReset = async (id, password) => (
//     await instance.post(`users/resetpass/${id}`, {password})
// );

// export const login = async (email, password) => {
//     console.log("Attempting to login to:", `${config.WS_BASE_URL}/users/login`);
//     return await instance.post('/users/login', { email, password });
// };

// // export const logout = async token => (
// //     await instance.post('users/logout', {token})
// // );
// export const logout = async token => {
//     console.log("Sending logout request with token:", token);
//     return await instance.post('users/logout', {token});
// };

// export const edit = async (userID, name, email) => (
//     await instance.post('/users/edit', {userID, name, email})
// );
import axios from 'axios';
import config from "../config";

const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    // config.headers.Authorization = (token ? token : '');
    // config.headers.ContentType = 'application/json';
    config.headers.Authorization = token ? `Bearer ${token}` : ''; // Corrige l'en-tête Authorization
    config.headers['Content-Type'] = 'application/json';
    return config;
});

export const getAll = async () => (
    await instance.post('users/all')
);

export const register = async (name, email, password) => {
    console.log("Attempting to register to:", `${config.WS_BASE_URL}users/register`);
    return await instance.post('/users/register', { name, email, password });
};

export const confirmRegister = async id => (
    await instance.post(`users/confirm/${id}`)
);

export const forgotPassword = async email => (
    await instance.post('users/forgotpassword', {email})
);

export const confirmReset = async (id, password) => (
    await instance.post(`users/resetpass/${id}`, {password})
);

export const login = async (email, password) => {
    console.log("Attempting to login to:", `${config.WS_BASE_URL}/users/login`);
    return await instance.post('/users/login', { email, password });
};

// export const logout = async token => (
//     await instance.post('users/logout', {token})
// );
export const logout = async token => {
    console.log("Sending logout request with token:", token);
    return await instance.post('users/logout', {token});
};

// export const edit = async (userID, name, email) => (
//     await instance.post('/users/edit', {userID, name, email})
// );

export const edit = async (userID, userDetails) => (
    await instance.post('/users/edit', {
        userID,
        ...userDetails
    })
);

