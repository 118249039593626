import React from "react";
import {  Container, Row} from "reactstrap";

class HeaderA extends React.Component {
  
 
  render() {
   
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-8" style={{ background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row></Row>
                
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default HeaderA;