import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeft } from 'react-icons/fa';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FaPencilAlt } from 'react-icons/fa'; // Utilise FontAwesome pour l'icône de crayon


import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody, CardTitle, CardHeader, Form, FormGroup, Input, Label, Container, Row, Col, UncontrolledAlert,
  Nav, NavItem, NavLink, TabContent, TabPane, ListGroup,
  ListGroupItem,
  Badge
} from "reactstrap";
import classnames from 'classnames';
import EarRight from '../../assets/img/theme/gauche.jpg'; // Remplacez 'path/to' par le chemin approprié
import EarLeft from '../../assets/img/theme/droite.jpg';  // Remplacez 'path/to' par le chemin approprié
import LinkIcon from '../../assets/img/theme/link.jpg';

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}


const stepStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  marginBottom: '20px'
};





const NouveauClient = () => {
  const history = useHistory();
  const location = useLocation();
  const [client, setClient] = useState({
    nom: "",
    prenom: "",
    dateNaissance: "",
    mutuelle: "",
    numeroSecu: "",
    caisse: "",
    email: "",
    telephoneFixe: "",
    telephonePortable: "",
    adresse: "",
    codePostal: "",
    ville: "",
    note: "",
    audiogramme: "",
    statut: "",
    origine: "",
    dateAppareillage: "", // New field for appareillage date
    dateFacturation: "",
    codeProduit: "",
    quantity: "",
    RbstSS: "",
    RbstCompl: "",
    Remise: ""

  });
  const [isEditable, setIsEditable] = useState(!location.state || !location.state.client);
  const [activeTab, setActiveTab] = useState('1');
  const [audiogrammeSuccessMessage, setAudiogrammeSuccessMessage] = useState("");
  const [facture, setFacture] = useState({ articles: [] }); // Ensure articles is always an array
  const [showFactureModal, setShowFactureModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [token, setToken] = useState('');
  const [orlOptions, setOrlOptions] = useState([]);
  const [selectedOrl, setSelectedOrl] = useState(null);

  useEffect(() => {
    const authenticate = async () => {
      const myHeaders = new Headers({
        "Content-Type": "application/json"
      });

      const raw = JSON.stringify({
        "username": "esther.e@ouieqare.com",
        "password": "Jerusa1967"
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      try {
        const response = await fetch("https://production.api-annuaire-sante.fr/login_check", requestOptions);
        const result = await response.json();
        setToken(result.token);
      } catch (error) {
        console.error('Error during authentication', error);
      }
    };

    authenticate();
  }, []);

  //     useEffect(() => {
  //         if (token) {
  //             const myHeaders = new Headers({
  //                 "Authorization": `Bearer ${token}`
  //             });

  //             const requestOptions = {
  //                 method: 'GET',
  //                 headers: myHeaders,
  //                 redirect: 'follow'
  //             };

  //             fetch('https://production.api-annuaire-sante.fr/professionnel_de_santes', requestOptions)
  //             .then(response => response.json())
  //             .then(result => {
  //                 // Vérifier si la clé hydra:member existe et est un tableau
  //                 if (result['hydra:member'] && Array.isArray(result['hydra:member'])) {
  //                     const options = result['hydra:member'].map(orl => ({
  //                         label: `${orl.nom} ${orl.prenom}`,
  //                         value: orl.id
  //                     }));
  //                     setOrlOptions(options);
  //                 } else {
  //                     console.error('Expected an array but got:', result);
  //                 }
  //             })
  //             .catch(error => console.error('error', error));
  //     }
  // }, [token]);

  useEffect(() => {
    const fetchAllDoctors = async (url) => {
      const headers = new Headers({
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      });

      try {
        const response = await fetch(url, { headers });
        const result = await response.json();
        if (result['hydra:member']) {
          setOrlOptions(prevOptions => [...prevOptions, ...result['hydra:member'].map(orl => ({
            label: `${orl.nom} ${orl.prenom}`,
            value: orl.id
          }))]);

          // Si il y a une page suivante, faites un appel récursif
          if (result['hydra:view'] && result['hydra:view']['hydra:next']) {
            fetchAllDoctors(result['hydra:view']['hydra:next']);
          }
        } else {
          console.error('Expected an array but got:', result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (token) {
      fetchAllDoctors('https://production.api-annuaire-sante.fr/professionnel_de_santes');
    }
  }, [token]); // Assurez-vous de déclencher l'effet lorsque le token change



  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    // Si un client est passé dans l'état, utilisez ses valeurs pour initialiser le formulaire
    //   if (location.state && location.state.client && location.state.client._id) {
    //     const formattedClient = {
    //       ...location.state.client,
    //       dateNaissance: formatDate(location.state.client.dateNaissance),
    //       dateAppareillage: formatDate(location.state.client.dateAppareillage),
    //       dateFacturation: formatDate(location.state.client.dateFacturation),
    //       prescripteurId: location.state.client.prescripteurId
    //     };
    //     setClient(formattedClient);
    //     if (location.state.client.prescripteurId && orlOptions.length) {
    //       const selected = orlOptions.find(option => option.value === location.state.client.prescripteurId);
    //       setSelectedOrl(selected || null);
    //     }
    //   }
    // }, [location.state, orlOptions]);

    if (location.state && location.state.client && location.state.client._id) {
      const loadClientData = async () => {
        const response = await fetch(`https://app.vtalys.fr/api/clients/${location.state.client._id}`);
        const data = await response.json();
        if (response.ok) {
          const formattedClient = {
            ...data,
            dateNaissance: formatDate(data.dateNaissance),
            dateAppareillage: formatDate(data.dateAppareillage),
            dateFacturation: formatDate(data.dateFacturation),
            prescripteurId: data.prescripteurId
          };
          setClient(formattedClient);
          // Mettre à jour le prescripteur sélectionné si nécessaire
          if (data.prescripteurId && orlOptions.length) {
            const selected = orlOptions.find(option => option.value === data.prescripteurId);
            setSelectedOrl(selected || null);
          }
        } else {
          console.error('Failed to fetch client details:', data);
        }
      };

      loadClientData();
    }
  }, [location.state, orlOptions]);
  //laaa
  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const isMounted = useRef(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;  // Nettoyage en démontant le composant
    };
  }, []);

  const validateForm = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!client.nom.trim()) {
      formIsValid = false;
      tempErrors["nom"] = "Le nom est requis.";
    }

    if (!client.email.trim()) {
      formIsValid = false;
      tempErrors["email"] = "L'email est requis.";
    }

    setErrors(tempErrors);
    return formIsValid;
  };


  const saveClient = async (clientData) => {
    const url = clientData._id ? `https://app.vtalys.fr/api/clients/${clientData._id}` : 'https://app.vtalys.fr/api/clients/add';
    const method = clientData._id ? 'PUT' : 'POST';

    console.log('Envoi des données du client:', clientData);  // Log des données envoyées

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(clientData)
      });

      console.log('Statut de la réponse:', response.status);  // Log du statut de réponse HTTP

      if (!response.ok) {
        const data = await response.json();
        console.log('Erreur lors de l\'enregistrement:', data);  // Log de l'erreur
        toast.error(`Erreur: ${data.message}`);
        return;
      }

      const data = await response.json();
      console.log('Réponse du serveur:', data);  // Log de la réponse
      toast.success("Le client a été ajouté/modifié avec succès !");
      setIsEditable(false);
      // setTimeout(() => {
      //     history.push('/admin/clients');
      // }, 3000);
      if (clientData.statut === "Facturé") {
        // Génération de la facture lorsque le client est facturé
        generateInvoice(data._id || clientData._id, {
          totalGeneral: clientData.prix || 0, // Assurez-vous que ce champ est bien rempli
          articles: [{ description: 'Appareil auditif', quantite: 1, prix: clientData.prix || 1200 }] // Exemple
        });
      }

    } catch (error) {
      console.error('Erreur lors de l\'opération sur le client:', error);
      toast.error(`Erreur lors de l'opération sur le client: ${error.message}`);
    }
  };

  const handleSelectChange = selectedOption => {
    setSelectedOrl(selectedOption);  // Pour gérer l'état local de l'option sélectionnée
    // Mettre à jour l'état global du client pour le prescripteur
    setClient(prevState => ({
      ...prevState,
      prescripteurId: selectedOption ? selectedOption.value : ''
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClient({ ...client, [name]: value });
    // Clear errors
    if (!!errors[name]) setErrors({ ...errors, [name]: null });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      saveClient(client);
    }
  };
  const handleSubmitAudiogramme = async (e) => {
    e.preventDefault();
    const audiogrammeData = {
      ...client,
      audiogramme: client.audiogramme
    };

    const url = client._id ? `https://app.vtalys.fr/api/clients/${client._id}` : 'https://app.vtalys.fr/api/clients/add';
    const method = client._id ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(audiogrammeData)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP status ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      console.log('Opération réussie:', data);
      setAudiogrammeSuccessMessage("Le bilan auditif a été enregistré avec succès.");
      setTimeout(() => {
        setAudiogrammeSuccessMessage("");
        history.push('/admin/clients'); // Redirection après l'affichage du message de succès
      }, 3000); // Affichage du message pendant 3 secondes

    } catch (error) {
      console.error("Erreur lors de l'enregistrement du bilan auditif:", error);
      setErrors({ form: "Erreur lors de l'opération sur le bilan auditif." });
    }
  };
  const handleBack = () => {
    history.goBack();
  };

  const handleSubmitAppareillage = async (e) => {
    e.preventDefault();

    // Collecter les données d'appareillage
    const appareillageData = {
      ...client, // conserve les données existantes du client
      marqueAppareilG: client.marqueAppareilG,
      modelAppareilG: client.modelAppareilG,
      codeProduitG: client.codeProduitG,
      lppG: client.lppG,
      quantityG: client.quantityG,
      montantAppareilG: client.montantAppareilG,
      marqueAppareilD: client.marqueAppareilD,
      modelAppareilD: client.modelAppareilD,
      codeProduitD: client.codeProduitD,
      lppD: client.lppD,
      quantityD: client.quantityD,
      montantAppareilD: client.montantAppareilD
    };

    // URL et méthode pour l'API
    const url = client._id ? `https://app.vtalys.fr/api/clients/${client._id}` : 'https://app.vtalys.fr/api/clients/add';
    const method = client._id ? 'PUT' : 'POST';

    // Appel API pour enregistrer les données
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(appareillageData)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP status ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      console.log('Opération réussie:', data);
      setClient(data);
      setAudiogrammeSuccessMessage("L'appareillage a été enregistré avec succès.");
      setTimeout(() => {
        setAudiogrammeSuccessMessage(""); // Effacer le message après 3 secondes
        history.push('/admin/clients'); // Redirection après l'affichage du message de succès
      }, 3000);

    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'appareillage:", error);
      setAudiogrammeSuccessMessage(`Erreur lors de l'opération: ${error.message}`);
    }
  };


  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setClient({
  //     ...client,
  //     appareillage: {
  //       ...client.appareillage,
  //       [name]: value
  //     }
  //   });
  // };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setClient({
      ...client,
      appareillage: {
        ...client.appareillage,
        [name]: checked
      }
    });
  };

  // const generatePDF = () => {
  //   const devis = client; // Utilisation de l'état client existant pour générer le devis
  //   const doc = new jsPDF();

  //   // En-tête du devis
  //   doc.setFontSize(18);
  //   doc.text('Devis pour Appareillage Auditif', 105, 25, null, null, 'center');
  //   doc.setFontSize(11);
  //   doc.text(`Date: ${new Date().toISOString().split('T')[0]}`, 200, 30, null, null, 'right'); // Date actuelle formatée

  //   // Informations du client
  //   doc.setFontSize(13);
  //   doc.text(`Nom du Client: ${devis.nom} ${devis.prenom}`, 20, 50);
  //   doc.text(`Email: ${devis.email}`, 20, 65);

  //   // Informations supplémentaires
  //   doc.text(`Adresse: ${devis.adresse}, ${devis.codePostal} ${devis.ville}`, 20, 80);
  //   doc.text(`Téléphone: ${devis.telephonePortable}`, 20, 95);

  //   // Ajout d'une table pour les détails de l'appareillage (exemple statique)
  //   autoTable(doc, {
  //     theme: 'grid',
  //     head: [['Article', 'Quantité', 'Prix Unitaire', 'Total']],
  //     body: [
  //       ['Appareil Auditif', '1', '1200.00 €', '1200.00 €']
  //       // Vous pouvez ajouter plus de lignes ici selon les données de l'état `client`
  //     ],
  //     startY: 110
  //   });

  //   // Affichage du total
  //   doc.text('Total Général: 1200.00 €', 20, doc.lastAutoTable.finalY + 20);

  //   // Sauvegarde du PDF
  //   doc.save('Devis.pdf');
  // };

  const downloadPDF = async () => {
    try {
      const response = await fetch('https://app.vtalys.fr/api/clients/generate-pdf', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      });
      if (response.ok) {
        // Crée une URL Blob et ouvre un nouvel onglet pour télécharger le PDF
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'devis.pdf'); // Nom du fichier à télécharger
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success("Le PDF a été téléchargé avec succès !");
      } else {
        throw new Error('Problème lors du téléchargement du PDF');
      }
    } catch (error) {
      console.error('Erreur lors du téléchargement du PDF:', error);
      toast.error("Erreur lors du téléchargement du PDF");
    }
  };

  const generateInvoice = async (clientId, factureData) => {
    try {
      const response = await fetch(`https://app.vtalys.fr/api/factures/generate/${clientId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify({
          ...factureData,
          dateFacture: factureData.dateFacture || new Date().toISOString().split('T')[0]  // Ajoutez la date actuelle si nécessaire
        })
      });

      if (!response.ok) {
        const data = await response.json();
        toast.error(`Erreur lors de la génération de la facture: ${data.message}`);
        return;
      }

      toast.success("Facture générée avec succès !");
    } catch (error) {
      toast.error(`Erreur lors de la génération de la facture: ${error.message}`);
    }
  };

  useEffect(() => {
    if (client.statut === "Facturé") {
      setShowFactureModal(true);
    } else {
      setShowFactureModal(false);
    }
  }, [client.statut]);

  const handleStatutChange = (event) => {
    setClient({ ...client, statut: event.target.value });
  };

  const handleModalInputChange = (event) => {
    setClient({ ...client, [event.target.name]: event.target.value });
  };

  const handleSubmitFacture = () => {
    // traitement pour sauvegarder ou traiter les informations de facturation
    setShowFactureModal(false);
    // Sauvegarde des données ou autre logique
  };

  const steps = [
    { id: '1', name: 'Informations Générales', path: '#informations-generales', icon: 'ni ni-badge' },
    { id: '2', name: 'Bilan Auditif', path: '#bilan-auditif', icon: 'ni ni-headphones' },
    { id: '3', name: 'Devis', path: '#devis', icon: 'ni ni-single-copy-04' },
    { id: '4', name: 'Appareillage', path: '#appareillage', icon: 'ni ni-vector' },
    { id: '5', name: 'Historique', path: '#historique', icon: 'ni ni-folder-17' }
  ];


  const navLinkStyle = {
    padding: '8px 16px',
    borderRadius: '8px', // Border-radius toujours appliqué
    backgroundColor: '#f8f9fa',
    border: '1px solid #ddd',
    color: '#007BFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontWeight: '500',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '15px',
    outline: 'none',
  };

  // Styles pour les NavLink actifs
  const navLinkActiveStyle = {
    ...navLinkStyle, // Inherit all common styles including border-radius
    backgroundColor: '#007D70',
    color: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transform: 'scale(1.05)',
  };


  const EarCardStyle = {
    borderRadius: '12px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    marginTop: '30px',
    marginBottom: '30px',
    padding: '20px',
    background: 'linear-gradient(87deg, #f0f8ff, #e6f7ff)',
    color: 'white',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    maxWidth: '400px', // Réduit la largeur maximale
    width: '100%',
  };

  const labelStyle = {
    color: 'black',
    fontSize: '13px',
    marginBottom: '5px',
  };

  const formGroupStyle = {
    marginBottom: '10px',
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',  // Centrer horizontalement
    alignItems: 'center',  // Centrer verticalement
    gap: '20px',  // Espacement entre les cartes
    marginBottom: '20px',
    flexWrap: 'wrap', // Permettre un bon alignement sur les petits écrans
  };
  useEffect(() => {
    console.log("Options prescripteurs:", orlOptions);
  }, [orlOptions]);

  useEffect(() => {
    console.log("Selected prescripteur:", selectedOrl);
  }, [selectedOrl]);

  const historique = [
    {
      date: '2024-09-01',
      action: 'Création du compte',
      details: 'Le compte client a été créé avec succès.',
      statut: 'Terminé'
    },
    {
      date: '2024-09-05',
      action: 'Modification des informations',
      details: 'Le client a mis à jour son adresse et son numéro de téléphone.',
      statut: 'Terminé'
    },
    {
      date: '2024-09-10',
      action: 'Ajout d’une nouvelle facture',
      details: 'Une facture de 850€ a été ajoutée au compte du client.',
      statut: 'En cours'
    }
  ];

  const [isFlipped, setIsFlipped] = useState({
    right: false,
    left: false
  });

  const [hovered, setHovered] = useState(false); // État pour gérer le survol


  const handleEditToggle = (e) => {
    e.stopPropagation();
    setIsEditable((prev) => !prev);
  };


  const [isEditing, setIsEditing] = useState({ left: false, right: false });

  const toggleFlip = (side) => {
    setIsFlipped(prev => ({
      ...prev,
      [side]: !prev[side]
    }));
  };

  const toggleEdit = (side) => {
    setIsEditing((prev) => ({ ...prev, [side]: !prev[side] }));
  };

  // Styles
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      height: '80vh',
      paddingTop: '20px',
      gap: '80px', // Augmente l'espacement entre les cartes et l'image du lien
      position: 'relative',
    },
    card: {
      width: '300px',
      height: '500px',
      perspective: '1000px',
      border: '2px solid #007D70',
      borderRadius: '10px',
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      position: 'relative',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
      overflow: 'hidden',
      cursor: 'pointer',
    },
    cardInner: {
      position: 'relative',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      transition: 'transform 0.6s',
      transformStyle: 'preserve-3d',
    },
    cardFace: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backfaceVisibility: 'hidden',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between', // Espace les éléments de manière uniforme
      alignItems: 'center',
      padding: '40px 20px', // Augmente le padding pour plus d'espace intérieur
      boxSizing: 'border-box',
    },
    cardBack: {
      position: 'relative', // Assurez-vous que le parent a 'position: relative'
      padding: '20px', // Ajuster le padding si nécessaire
      transform: 'rotateY(180deg)',
    },
    image: {
      width: '150px',
      height: '150px',
      marginBottom: '20px' // Espace entre l'image et le texte
    },
    button: {
      padding: '15px',
      border: 'none',
      borderRadius: '5px',
      background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
      color: 'white',
      fontSize: '16px',
      cursor: 'pointer',
      marginTop: '20px', // Espace entre le texte et le bouton
      transition: 'transform 0.3s ease',
      width: '80%',
      maxWidth: '180px',
    },
    saveButton: {
      marginTop: '10px',
      padding: '10px 20px',
      borderRadius: '8px',
      background: '#28a745',
      border: 'none',
      color: 'white',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    },
    pencilButton: {
      position: 'absolute',
      top: '10px', // Ajustez cette valeur pour l'espacement
      right: '10px', // Positionne le bouton à droite
      width: '30px', // Limite la largeur du bouton
      height: '30px', // Limite la hauteur du bouton
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px', // Ajustez le padding si nécessaire
      borderRadius: '50%', // Rend le bouton rond
      transition: 'all 0.3s ease', // Transition pour l'effet de survol
      background: 'linear-gradient(87deg, #17a2b8 0%, #138496 100%)',
      border: 'none',
      color: 'white',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      fontSize: '14px',
    },
    pencilButtonHover: {
      transform: 'scale(1.2)', // Agrandit le bouton à 120% de sa taille normale
    },

    linkContainer: {
      position: 'absolute',
      top: '50%', // Centre verticalement par rapport aux cartes
      left: '50%', // Centre horizontalement par rapport au conteneur
      transform: 'translate(-50%, -50%)', // Ajuste pour centrer parfaitement
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    linkIcon: {
      width: '50px',
      height: '50px',
    },
    pencilIcon: {
      width: '16px',
      height: '16px',
    },
    detailsTitle: {
      marginTop: '30px', // Augmentez cette valeur pour déplacer le titre plus bas
      paddingTop: '20px', // Ajoutez un padding supérieur si nécessaire
      fontSize: '18px', // Taille du texte du titre
      fontWeight: 'bold', // Rendre le titre en gras
      textAlign: 'center', // Centre le texte
      position: 'relative', // Permet un positionnement plus flexible
      top: '10px', 
    },
  };


  return (
    //  <div style={{ paddingTop: '50px', background: 'linear-gradient(87deg, #11cdef 0, #1171ef 100%)' }}>
    <div style={{ paddingTop: '50px', paddingBottom: '30px', background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)' }}>
      <Container className="mt-5">
        <Card style={{ paddingBottom: '20px' }}>
          <ToastContainer position="bottom-left" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          {/* <CardHeader className="bg-white text-white">
          <Button color="link" onClick={handleBack} style={{ marginRight: '20px', color: 'black' }}>
              <FaArrowLeft />
            </Button>
  <h4 className="mb-0">{client._id ? "Modifier Client" : "Ajouter Nouveau Client"}</h4>
  <div style={{ float: 'right' }}>
    {client._id && !isEditable && (
      <>
        <Button color="info" onClick={() => setIsEditable(true)} disabled={isEditable}>Modifier</Button>
        <Button color="primary" onClick={() => {
          if (validateForm()) {
            saveClient(client);
            setIsEditable(false); // Désactiver les champs après la sauvegarde
          }
        }} disabled={!isEditable} style={{ marginLeft: '10px' }}>Enregistrer Modifications</Button>
      </>
    )}
  </div>
</CardHeader> */}
          <CardHeader className="bg-white text-white">
            <Button color="link" onClick={handleBack} style={{ marginRight: '20px', color: 'black' }}>
              <FaArrowLeft />
            </Button>
            <h4 className="mb-0">{client._id ? "Modifier Client" : "Ajouter Nouveau Client"}</h4>
            addLineBreakAfter(headerTitle);
            <div style={{ marginTop: '10px' }}> {/* Controlled space for line break in React */}
              {client._id && !isEditable && (
                <>
                  <Button color="info" onClick={() => setIsEditable(true)} disabled={isEditable}>Modifier</Button>
                  <Button color="primary" onClick={() => {
                    if (validateForm()) {
                      saveClient(client);
                      setIsEditable(false); // Désactiver les champs après la sauvegarde
                    }
                  }} disabled={!isEditable} style={{ marginLeft: '10px' }}>Enregistrer Modifications</Button>
                </>
              )}

              <Nav style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginBottom: '20px'
              }}>
                {steps.map(step => (
                  <NavItem key={step.id}>
                    <NavLink
                      className={classnames({ active: activeTab === step.id })}
                      onClick={() => toggleTab(step.id)}
                      style={activeTab === step.id ? navLinkActiveStyle : navLinkStyle}
                      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    >
                      <i className={`${step.icon}`} style={{ paddingRight: '8px', color: '#28a745' }} />
                      {step.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

            </div>
          </CardHeader>


          <CardBody>

            <Nav tabs>
              {/* <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggleTab('1'); }}
                  style={{ cursor: 'pointer' }}
                >
                  Informations Générales
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggleTab('2'); }}
                  style={{ cursor: 'pointer' }}
                >
                  Bilan Auditif
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggleTab('3'); }}
                  style={{ cursor: 'pointer' }}
                >
                  Devis
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => { toggleTab('4'); }}
                  style={{ cursor: 'pointer' }}
                >
                  Appareillage
                </NavLink>
              </NavItem> */}
            </Nav>


            <div style={{ height: '1px', backgroundColor: 'transparent', marginTop: '10px', marginBottom: '10px' }}></div>
            <div style={{ float: 'right' }}>
              {client._id && (
                <>
                  <Button color="info" onClick={() => setIsEditable(true)} disabled={isEditable}>Modifier</Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (validateForm()) {
                        saveClient(client);
                      }
                    }}
                    disabled={!isEditable}
                    style={{
                      marginLeft: '10px',
                      background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)',
                      border: 'none' // Ajouté pour enlever la bordure par défaut qui peut altérer le rendu du dégradé
                    }}
                  >
                    Enregistrer Modifications
                  </Button>

                </>
              )}
            </div>
            <div style={{ height: '1px', backgroundColor: 'transparent', marginTop: '10px', marginBottom: '10px' }}></div>
            <TabContent activeTab={activeTab} className="mt-3">
              <TabPane tabId="1">
                {successMessage && (
                  <UncontrolledAlert
                    color="success"
                    className="fixed-alert"
                    style={{ position: 'fixed', top: '0', left: '0', right: '0', zIndex: '1050' }}
                    fade={false}
                  >
                    <span className="alert-inner--icon">
                      <i className="ni ni-like-2" />
                    </span>
                    <span className="alert-inner--text">
                      <strong>Succès!</strong> {successMessage}
                    </span>
                  </UncontrolledAlert>
                )}
                {/* Bouton Modifier placé en haut */}

                <Card style={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', overflow: 'hidden', margin: '20px 0' }}>
                  <CardHeader style={{ background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)', color: 'white', textAlign: 'center', padding: '15px 0', fontSize: '1.2rem', fontWeight: 'bold' }}>
                    Informations Client
                  </CardHeader>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0', marginRight: '15px' }}>
                    <Button
                      type="button"
                      color="info"
                      onClick={() => setIsEditable(true)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        transition: 'all 0.3s ease',
                        background: 'linear-gradient(87deg, #17a2b8 0%, #138496 100%)',
                        border: 'none',
                        color: 'white',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        fontSize: '16px'
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                      onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                      disabled={isEditable}
                    >
                      {/* modifier */}
                      <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '15px' }} />

                    </Button>
                  </div>
                  <CardBody style={{ padding: '30px', background: '#f8f9fa' }}>
                    <Form onSubmit={handleSubmit}>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="nom">
                              Nom <span style={{ color: 'red', position: 'relative', fontSize: '15px' }}>*</span>
                            </Label>
                            <Input
                              type="text"
                              name="nom"
                              id="nom"
                              value={client.nom}
                              onChange={handleInputChange}
                              required
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le nom du client"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                            {errors.nom && <p style={{ color: 'red' }}>{errors.nom}</p>}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="prenom">Prénom</Label>
                            <Input
                              type="text"
                              name="prenom"
                              id="prenom"
                              value={client.prenom}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le prénom du client"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="dateNaissance">Date de Naissance</Label>
                            <Input
                              type="date"
                              name="dateNaissance"
                              id="dateNaissance"
                              value={client.dateNaissance || ''}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="mutuelle">Mutuelle</Label>
                            <Input
                              type="text"
                              name="mutuelle"
                              id="mutuelle"
                              value={client.mutuelle}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez la mutuelle"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="numeroSecu">Numéro de Sécurité Sociale</Label>
                            <Input
                              type="text"
                              name="numeroSecu"
                              id="numeroSecu"
                              value={client.numeroSecu}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le numéro de sécurité sociale"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="caisse">Caisse</Label>
                            <Input
                              type="text"
                              name="caisse"
                              id="caisse"
                              value={client.caisse}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez la caisse"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="statut">Statut</Label>
                            <Input
                              type="select"
                              name="statut"
                              id="statut"
                              value={client.statut}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            >
                              <option value="">Sélectionner un statut</option>
                              <option value="Rdv fixé">Rdv fixé</option>
                              <option value="Rdv Annulé">Rdv Annulé</option>
                              <option value="Appareillé">Appareillé</option>
                              <option value="Période d'essai">Période d'essai</option>
                              <option value="Facturé">Facturé</option>
                            </Input>
                            {(client.statut === "Appareillé" || client.statut === "Facturé") && (
                              <FormGroup>
                                <Label for="dateAppareillage">Date d'Appareillage</Label>
                                <Input
                                  type="date"
                                  name="dateAppareillage"
                                  id="dateAppareillage"
                                  value={client.dateAppareillage}
                                  onChange={handleInputChange}
                                  disabled={!isEditable}
                                  className="form-control-alternative"
                                  style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                                />
                              </FormGroup>
                            )}
                            {client.statut === "Facturé" && (
                              <FormGroup>
                                <Label for="dateFacturation">Date de Facturation</Label>
                                <Input
                                  type="date"
                                  name="dateFacturation"
                                  id="dateFacturation"
                                  value={client.dateFacturation}
                                  onChange={handleInputChange}
                                  disabled={!isEditable}
                                  className="form-control-alternative"
                                  style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                                />
                              </FormGroup>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="origine">Origine</Label>
                            <Input
                              type="select"
                              name="origine"
                              id="origine"
                              value={client.origine}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            >
                              <option value="">Sélectionner une origine</option>
                              <option value="Site">Site</option>
                              <option value="Facebook">Facebook</option>
                              <option value="Ouieqare">Ouieqare</option>
                              <option value="Audibene">Audibene</option>
                              <option value="Direct">Direct</option>
                              <option value="Google">Google</option>
                              <option value="Doctolib">Doctolib</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="telephoneFixe">Téléphone Fixe</Label>
                            <Input
                              type="text"
                              name="telephoneFixe"
                              id="telephoneFixe"
                              value={client.telephoneFixe}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le téléphone fixe"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="telephonePortable">Téléphone Portable</Label>
                            <Input
                              type="text"
                              name="telephonePortable"
                              id="telephonePortable"
                              value={client.telephonePortable}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le téléphone portable"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="codePostal">Code Postal</Label>
                            <Input
                              type="text"
                              name="codePostal"
                              id="codePostal"
                              value={client.codePostal}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez le code postal"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="ville">Ville</Label>
                            <Input
                              type="text"
                              name="ville"
                              id="ville"
                              value={client.ville}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              placeholder="Entrez la ville"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup>
                        <Label for="adresse">Adresse</Label>
                        <Input
                          type="text"
                          name="adresse"
                          id="adresse"
                          value={client.adresse}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className="form-control-alternative"
                          placeholder="Entrez l'adresse"
                          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', minHeight: '50px' }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="email">
                          Email <span style={{ color: 'red', position: 'relative', fontSize: '15px' }}>*</span>
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          value={client.email}
                          onChange={handleInputChange}
                          required
                          disabled={!isEditable}
                          className="form-control-alternative"
                          placeholder="Entrez l'email"
                          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                        />
                        {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                      </FormGroup>

                      <FormGroup>
                        <Label for="input-prescripteur" className="form-control-label">Prescripteur</Label>
                        <Select
                          id="input-prescripteur"
                          value={selectedOrl}
                          onChange={handleSelectChange}
                          options={orlOptions}
                          classNamePrefix="select"
                          placeholder="Choisir un prescripteur"
                          isClearable={true}
                          isSearchable={true}
                          isDisabled={!isEditable}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              minHeight: 'calc(1.5em + 1.3rem + 2px)',
                              height: 'calc(2.4rem + 2px)',
                              borderRadius: '8px',
                              border: '1px solid #ddd'
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              height: 'calc(2.4rem + 2px)'
                            })
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="note">Note</Label>
                        <Input
                          type="textarea"
                          name="note"
                          id="note"
                          value={client.note}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className="form-control-alternative"
                          placeholder="Entrez une note"
                          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px', minHeight: '100px', maxHeight: '300px' }}
                        />
                      </FormGroup>

                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>


                        <Button
                          type="submit"
                          color="primary"
                          disabled={!isEditable}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            transition: 'all 0.3s ease',
                            background: 'linear-gradient(87deg, #007BFF 0%, #0056b3 100%)',
                            border: 'none',
                            color: 'white',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            fontSize: '16px',
                          }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        >
                          <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
                          Enregistrer
                        </Button>

                        <Button
                          type="button"
                          color="secondary"
                          onClick={() => history.push('/admin/clients')}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            transition: 'all 0.3s ease',
                            background: 'linear-gradient(87deg, #6c757d 0%, #5a6268 100%)',
                            border: 'none',
                            color: 'white',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            fontSize: '16px',
                          }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        >
                          <FontAwesomeIcon icon={faTimes} style={{ marginRight: '8px' }} />
                          Annuler
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </TabPane>

              <TabPane tabId="2">
                {audiogrammeSuccessMessage && (
                  <UncontrolledAlert color="success" className="fixed-alert" fade={false}>
                    <span className="alert-inner--icon"><i className="ni ni-like-2" /></span>
                    <span className="alert-inner--text"><strong>Succès!</strong> {audiogrammeSuccessMessage}</span>
                  </UncontrolledAlert>
                )}
                <Form onSubmit={handleSubmitAudiogramme} style={{ paddingTop: '50px' }}>
                  <FormGroup>
                    <Label for="audiogramme">Audiogramme</Label>
                    <Input type="text" name="audiogramme" id="audiogramme" value={client.audiogramme} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  {/* Ajoutez plus de champs selon vos besoins ici */}
                  <Button type="submit" color="primary">Enregistrer Bilan</Button>
                </Form>
              </TabPane>
              <TabPane tabId="3">
                {/* <Form onSubmit={handleSubmitAudiogramme} style={{ paddingTop: '50px' }}>
                  <FormGroup>
                    <Label for="typeAppareil">Type d'Appareil</Label>
                    <Input type="text" name="typeAppareil" id="typeAppareil" value={client.typeAppareil} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="modeleAppareil">Modèle de l'Appareil</Label>
                    <Input type="text" name="modeleAppareil" id="modeleAppareil" value={client.modeleAppareil} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="numeroSerie">Numéro de Série</Label>
                    <Input type="text" name="numeroSerie" id="numeroSerie" value={client.numeroSerie} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="prix">Prix</Label>
                    <Input type="number" name="prix" id="prix" value={client.prix} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="garantie">Durée de la Garantie</Label>
                    <Input type="text" name="garantie" id="garantie" value={client.garantie} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="servicesInclus">Services Inclus</Label>
                    <Input type="text" name="servicesInclus" id="servicesInclus" value={client.servicesInclus} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="acompte">Acompte Requis</Label>
                    <Input type="number" name="acompte" id="acompte" value={client.acompte} onChange={handleInputChange} disabled={!isEditable} />
                  </FormGroup>
                  <Button color="secondary" onClick={downloadPDF}>Générer Devis PDF</Button>
                  <Button type="submit" color="primary">Enregistrer Devis</Button>
                </Form> */}
                <Card style={{ borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', overflow: 'hidden', margin: '20px 0' }}>
                  <CardHeader style={{ background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)', color: 'white', textAlign: 'center', padding: '15px 0', fontSize: '1.2rem', fontWeight: 'bold' }}>
                    Devis
                  </CardHeader>
                  <CardBody style={{ padding: '30px', background: '#f8f9fa' }}>
                    <Form onSubmit={handleSubmitAudiogramme}>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="typeAppareil">Type d'Appareil</Label>
                            <Input
                              type="text"
                              name="typeAppareil"
                              id="typeAppareil"
                              placeholder="Ex: Appareil auditif"
                              value={client.typeAppareil}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="modeleAppareil">Modèle de l'Appareil</Label>
                            <Input
                              type="text"
                              name="modeleAppareil"
                              id="modeleAppareil"
                              placeholder="Ex: Modèle XYZ"
                              value={client.modeleAppareil}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="numeroSerie">Numéro de Série</Label>
                            <Input
                              type="text"
                              name="numeroSerie"
                              id="numeroSerie"
                              placeholder="Ex: 123456789"
                              value={client.numeroSerie}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="prix">Prix (€)</Label>
                            <Input
                              type="number"
                              name="prix"
                              id="prix"
                              placeholder="Ex: 1200"
                              value={client.prix}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="garantie">Durée de la Garantie</Label>
                            <Input
                              type="text"
                              name="garantie"
                              id="garantie"
                              placeholder="Ex: 2 ans"
                              value={client.garantie}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="servicesInclus">Services Inclus</Label>
                            <Input
                              type="text"
                              name="servicesInclus"
                              id="servicesInclus"
                              placeholder="Ex: Ajustements gratuits"
                              value={client.servicesInclus}
                              onChange={handleInputChange}
                              disabled={!isEditable}
                              className="form-control-alternative"
                              style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup>
                        <Label for="acompte">Acompte Requis (€)</Label>
                        <Input
                          type="number"
                          name="acompte"
                          id="acompte"
                          placeholder="Ex: 200"
                          value={client.acompte}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className="form-control-alternative"
                          style={{ padding: '12px', border: '1px solid #ddd', borderRadius: '8px' }}
                        />
                      </FormGroup>

                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button
                          color="secondary"
                          onClick={downloadPDF}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            transition: 'all 0.3s ease',
                            background: 'linear-gradient(87deg, #003D33 0%, #007D70 100%)',
                            border: 'none',
                            color: 'white',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            fontSize: '16px',
                          }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        >
                          <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '8px' }} />
                          Générer Devis PDF
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            transition: 'all 0.3s ease',
                            background: 'linear-gradient(87deg, #007BFF 0%, #0056b3 100%)',
                            border: 'none',
                            color: 'white',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            fontSize: '16px',
                          }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                          onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        >
                          <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
                          Enregistrer Devis
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </TabPane>

              {/* <TabPane tabId="4">
  <Form onSubmit={handleSubmitAppareillage} style={{ paddingTop: '50px' }}>
    <FormGroup>
      <Label for="marqueAppareilG">Produit 1 </Label>
      <Input type="text" name="marqueAppareilG" id="marqueAppareilG" value={client.marqueAppareilG} onChange={handleInputChange} />
    </FormGroup>
    <FormGroup>
      <Label for="marqueAppareil2">Produit 2 </Label>
      <Input type="text" name="marqueAppareil" id="marqueAppareil" value={client.marqueAppareil2} onChange={handleInputChange} />
    </FormGroup>
    <FormGroup>
      <Label for="codeProduit">Code du Produit</Label>
      <Input type="text" name="codeProduit" id="codeProduit" value={client.codeProduit} onChange={handleInputChange} />
    </FormGroup>
    <FormGroup>
              <Label for="productQuantity">Quantité</Label>
              <Input
                type="number"
                id="productQuantity"
                name="quantity"
                value={client.quantity}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
    <FormGroup>
      <Label for="montantAppareil">Montant du Produit</Label>
      <Input type="text" name="montantAppareil" id="montantAppareil" value={client.montantAppareil} onChange={handleInputChange} />
    </FormGroup>
    <FormGroup check>
      <Label check>
        <Input type="checkbox" name="monoAppareil" id="monoAppareil" checked={client.monoAppareil || false} onChange={handleCheckboxChange} />
        Mono Appareil
      </Label>
    </FormGroup>
    <FormGroup>
      <Label for="observations">Observations</Label>
      <Input type="textarea" name="Appobservations" id="Appobservations" value={client.Appobservations} onChange={handleInputChange} />
    </FormGroup>
    <Button type="submit" color="primary">Enregistrer Appareillage</Button>
  </Form>
</TabPane> */}

              {/* <TabPane tabId="4">
              {audiogrammeSuccessMessage && (
                  <UncontrolledAlert color="success" className="fixed-alert" fade={false}>
                    <span className="alert-inner--icon"><i className="ni ni-like-2" /></span>
                    <span className="alert-inner--text"><strong>Succès!</strong> {audiogrammeSuccessMessage}</span>
                  </UncontrolledAlert>
                )}
                <Form onSubmit={handleSubmitAppareillage} style={{ paddingTop: '30px' }}>
                  <div style={containerStyle}>
                    <div style={EarCardStyle}>
                      <h5><strong>Appareil Gauche</strong></h5>
                      {[
                        { id: 'marqueAppareilG', label: 'Marque' },
                        { id: 'modelAppareilG', label: 'Modèle' },
                        { id: 'codeProduitG', label: 'Code' },
                        { id: 'lppG', label: 'LPP' },
                        { id: 'quantityG', label: 'Quantité', type: 'number' },
                        { id: 'montantAppareilG', label: 'Montant HT' }
                      ].map(({ id, label, type }) => (
                        <FormGroup key={id} style={formGroupStyle}>
                          <Label for={id} style={labelStyle}>{label}</Label>
                          <Input
                            type={type || 'text'}
                            name={id}
                            id={id}
                            value={client.id}
                            onChange={handleInputChange}
                            style={{ fontSize: '12px', padding: '6px' }}
                            disabled={!isEditable} // Désactive le champ si isEditable est false
                          />
                        </FormGroup>
                      ))}
                    </div>

                    <div style={EarCardStyle}>
                      <h5><strong>Appareil Droit</strong></h5>
                      {[
                        { id: 'marqueAppareilD', label: 'Marque' },
                        { id: 'modelAppareilD', label: 'Modèle' },
                        { id: 'codeProduitD', label: 'Code' },
                        { id: 'lppD', label: 'LPP' },
                        { id: 'quantiteD', label: 'Quantité', type: 'number' },
                        { id: 'montantAppareilD', label: 'Montant HT' }
                      ].map(({ id, label, type }) => (
                        <FormGroup key={id} style={formGroupStyle}>
                          <Label for={id} style={labelStyle}>{label}</Label>
                          <Input
                            type={type || 'text'}
                            name={id}
                            id={id}
                            value={client.id}
                            onChange={handleInputChange}
                            style={{ fontSize: '12px', padding: '6px' }}
                            disabled={!isEditable} // Désactive le champ si isEditable est false
                          />
                        </FormGroup>
                      ))}
                    </div>
                  </div>
                  <Button type="submit" color="primary" style={{ width: '100%', maxWidth: '300px', margin: '0 auto', display: 'block' }}>Enregistrer Appareillage</Button>
                </Form>
              </TabPane> */}
              <TabPane tabId="4">

                <div style={styles.container}>
                  {/* Carte Oreille Gauche */}
                  <div
                    style={styles.card}
                    onClick={() => toggleFlip('left')}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  >
                    <div
                      style={{
                        ...styles.cardInner,
                        transform: isFlipped.left ? 'rotateY(180deg)' : 'rotateY(0)',
                      }}
                    >
                      {/* Face avant */}
                      <div style={styles.cardFace}>
                        <h2 style={styles.detailsTitle}>Oreille Gauche</h2>
                        <img src={EarLeft} alt="Illustration de l'oreille gauche" style={styles.image} />
                        <p style={{ fontSize: '16px' }}>Marque : {client.marqueAppareilG}</p>
                        <p style={{ fontSize: '16px' }}>Modèle : {client.modelAppareilG}</p>
                        <button
                          style={styles.button}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFlip('left');
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        >
                          {isFlipped.left ? 'Retourner' : "Afficher plus d'infos"}
                        </button>
                      </div>
                      {/* Face arrière */}
                      <div style={{ ...styles.cardFace, ...styles.cardBack }}>
                        <h2>Oreille Gauche - Détails</h2>
                        <button
                          type="button"
                          onClick={handleEditToggle}
                          style={{
                            ...styles.pencilButton,
                            ...(hovered ? styles.pencilButtonHover : {}),
                          }}
                          onMouseEnter={() => setHovered(true)}
                          onMouseLeave={() => setHovered(false)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                        {isEditable ? (
                          <>
                            <input
                              type="text"
                              name="codeProduitG"
                              value={client.codeProduitG || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Code"
                            />
                            <input
                              type="text"
                              name="lppG"
                              value={client.lppG || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="LPP"
                            />
                            <input
                              type="number"
                              name="quantityG"
                              value={client.quantityG || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Quantité"
                            />
                            <input
                              type="number"
                              name="montantAppareilG"
                              value={client.montantAppareilG || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Montant HT"
                            />
                            <button onClick={handleEditToggle} style={styles.saveButton}>
                              <FontAwesomeIcon icon={faSave} /> Enregistrer
                            </button>
                          </>
                        ) : (
                          <>
                            <p style={{ fontSize: '16px' }}><strong>Code : </strong>{client.codeProduitG}</p>
                            <p style={{ fontSize: '16px' }}><strong>LPP : </strong> {client.lppG}</p>
                            <p style={{ fontSize: '16px' }}><strong>Quantité : </strong> {client.quantityG}</p>
                            <p style={{ fontSize: '16px' }}><strong>Montant HT : </strong> {client.montantAppareilG}</p>
                          </>
                        )}
                        <button
                          style={styles.button}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFlip('left');
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Retourner
                        </button>
                      </div>
                    </div>
                  </div>

                  <div style={styles.linkContainer}>
                    <img src={LinkIcon} alt="Lien entre les cartes" style={styles.linkIcon} />
                  </div>

                  {/* Carte Oreille Droite */}
                  <div
                    style={styles.card}
                    onClick={() => toggleFlip('right')}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  >
                    <div
                      style={{
                        ...styles.cardInner,
                        transform: isFlipped.right ? 'rotateY(180deg)' : 'rotateY(0)',
                      }}
                    >
                      {/* Face avant */}
                      <div style={styles.cardFace}>
                        <h2 style={styles.detailsTitle} >Oreille Droite</h2>
                        <img src={EarRight} alt="Illustration de l'oreille Droite" style={styles.image} />
                        <p style={{ fontSize: '16px' }}>Marque : {client.marqueAppareilD}</p>
                        <p style={{ fontSize: '16px' }}>Modèle : {client.modelAppareilD}</p>
                        <button
                          style={styles.button}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFlip('right');
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        >
                          {isFlipped.right ? 'Retourner' : "Afficher plus d'infos"}
                        </button>
                      </div>
                      {/* Face arrière */}
                      <div style={{ ...styles.cardFace, ...styles.cardBack }}>
                        <h2>Oreille Droite - Détails</h2>
                        <button
                          type="button"
                          onClick={handleEditToggle}
                          style={{
                            ...styles.pencilButton,
                            ...(hovered ? styles.pencilButtonHover : {}),
                          }}
                          onMouseEnter={() => setHovered(true)}
                          onMouseLeave={() => setHovered(false)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                        {isEditable ? (
                          <>
                            <input
                              type="text"
                              name="codeProduitD"
                              value={client.codeProduitD || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Code"
                            />
                            <input
                              type="text"
                              name="lppD"
                              value={client.lppD || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="LPP"
                            />
                            <input
                              type="number"
                              name="quantityD"
                              value={client.quantityD || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Quantité"
                            />
                            <input
                              type="number"
                              name="montantAppareilD"
                              value={client.montantAppareilD || ''}
                              onChange={handleInputChange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Montant HT"
                            />
                            <button onClick={handleEditToggle} style={styles.saveButton}>
                              <FontAwesomeIcon icon={faSave} /> Enregistrer
                            </button>
                          </>
                        ) : (
                          <>
                            <p style={{ fontSize: '16px' }}><strong>Code : </strong>{client.codeProduitD}</p>
                            <p style={{ fontSize: '16px' }}><strong>LPP : </strong> {client.lppD}</p>
                            <p style={{ fontSize: '16px' }}><strong>Quantité : </strong>  {client.quantityD}</p>
                            <p style={{ fontSize: '16px' }}><strong>Montant HT : </strong>{client.montantAppareilD}</p>
                          </>
                        )}
                        <button
                          style={styles.button}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFlip('right');
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Retourner
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="5">

                <Container className="mt-5">
                  <Row>
                    <Col>
                      <Card className="shadow" style={{ borderRadius: '12px', color: 'white' }}>
                        <CardHeader className="d-flex justify-content-between align-items-center" style={{ backgroundColor: 'transparent', borderBottom: 'none' }}>
                          <h3 className="mb-0">Historique des Actions du Client</h3>
                        </CardHeader>
                        <CardBody>
                          <ListGroup flush>
                            {historique.map((item, index) => (
                              <ListGroupItem key={index} style={{ backgroundColor: 'transparent', border: 'none', color: 'white', marginBottom: '10px', padding: '15px', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)' }}>
                                <Row className="align-items-center">
                                  <Col md="3">
                                    <h5 className="mb-0">{item.date}</h5>
                                  </Col>
                                  <Col md="6">
                                    <h6 className="mb-0">{item.action}</h6>
                                    <small>{item.details}</small>
                                  </Col>
                                  <Col md="3" className="text-right">
                                    <Badge color={item.statut === 'Terminé' ? 'success' : 'warning'} pill>
                                      {item.statut}
                                    </Badge>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>

              </TabPane>

            </TabContent>

          </CardBody>
        </Card>

      </Container>
      {showFactureModal && (
        <Modal isOpen={showFactureModal} toggle={() => setShowFactureModal(!showFactureModal)}>
          <ModalHeader toggle={() => setShowFactureModal(!showFactureModal)}>Détails de Facturation</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Date de Facturation</Label>
              <Input
                type="date"
                name="dateFacturation"
                value={client.dateFacturation}
                onChange={handleModalInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Remise</Label>
              <Input
                type="number"
                name="remise"
                value={client.remise}
                onChange={handleModalInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Remboursement Sécurité Sociale</Label>
              <Input
                type="number"
                name="rbstSS"
                value={client.rbstSS}
                onChange={handleModalInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Remboursement Complémentaire</Label>
              <Input
                type="number"
                name="rbstCompl"
                value={client.rbstCompl}
                onChange={handleModalInputChange}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmitFacture}>Sauvegarder</Button>
            <Button color="secondary" onClick={() => setShowFactureModal(false)}>Annuler</Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}


export default NouveauClient;
