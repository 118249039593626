import React from 'react';
import {Route, Redirect} from 'react-router-dom';

const AuthRoutes = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        !(localStorage.getItem("token") && localStorage.getItem("user") || props.location.pathname.includes('/auth/google'))
            ? <Component {...props} />
            : <Redirect to='/admin/agenda' />
    )} />
);

export default AuthRoutes;
