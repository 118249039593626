import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader, Button, ListGroup, ListGroupItem, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from "reactstrap";
import HeaderAb from "components/Headers/HeaderAb.js";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MoyenDePaiementClient = () => {
  const [moyensPaiement, setMoyensPaiement] = useState([
    { id: 1, type: 'Carte de Crédit', details: 'Visa se terminant par 4242', statut: 'Actif', icone: 'ni ni-credit-card' },
    { id: 2, type: 'Portefeuille Électronique', details: 'PayPal (email@example.com)', statut: 'Inactif', icone: 'ni ni-money-coins' },
    { id: 3, type: 'Virement Bancaire', details: 'Compte SEPA se terminant par 1234', statut: 'Actif', icone: 'ni ni-bank' }
  ]);

  const [modal, setModal] = useState(false);
  const [newMoyen, setNewMoyen] = useState({ type: '', details: '', statut: 'Actif', icone: 'ni ni-credit-card' });

  const toggleModal = () => setModal(!modal);

  const handleAddMoyen = () => {
    const newId = moyensPaiement.length ? Math.max(...moyensPaiement.map(m => m.id)) + 1 : 1;
    setMoyensPaiement([...moyensPaiement, { ...newMoyen, id: newId }]);
    setNewMoyen({ type: '', details: '', statut: 'Actif', icone: 'ni ni-credit-card' });
    toggleModal();
    toast.success("Moyen de paiement ajouté avec succès !");
  };

  const handleDeleteMoyen = (id) => {
    setMoyensPaiement(moyensPaiement.filter(moyen => moyen.id !== id));
    toast.error("Moyen de paiement supprimé !");
  };

  const handleChangeStatus = (id) => {
    setMoyensPaiement(moyensPaiement.map(moyen =>
      moyen.id === id ? { ...moyen, statut: moyen.statut === 'Actif' ? 'Inactif' : 'Actif' } : moyen
    ));
    toast.info("Statut du moyen de paiement mis à jour !");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMoyen({ ...newMoyen, [name]: value });
  };

  return (
    <>
      <HeaderAb />
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Container className="mt-5">
        <Row>
          <Col>
            <Card className="shadow" style={{ borderRadius: '12px', background: 'linear-gradient(87deg, #003D33 0, #007D70 100%)', color: 'white' }}>
              <CardHeader className="d-flex justify-content-between align-items-center" style={{ backgroundColor: 'transparent', borderBottom: 'none' }}>
                <h3 className="mb-0">Moyens de Paiement du Client</h3>
                <Button color="light" style={{ color: '#007D70', borderRadius: '8px' }} onClick={toggleModal}>
                  <i className="ni ni-fat-add" style={{ paddingRight: '5px' }}></i> Ajouter un Moyen de Paiement
                </Button>
              </CardHeader>
              <CardBody>
                <ListGroup flush>
                  {moyensPaiement.map((moyen) => (
                    <ListGroupItem key={moyen.id} style={{ backgroundColor: '#f8f9fa', border: 'none', color: '#003D33', marginBottom: '10px', padding: '15px', borderRadius: '8px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}>
                      <Row className="align-items-center">
                        <Col md="2" className="text-center">
                          <i className={`${moyen.icone}`} style={{ fontSize: '30px', color: '#007D70' }}></i>
                        </Col>
                        <Col md="5">
                          <h5 className="mb-0">{moyen.type}</h5>
                          <small>{moyen.details}</small>
                        </Col>
                        <Col md="2" className="text-right">
                          <Badge color={moyen.statut === 'Actif' ? 'success' : 'secondary'} pill onClick={() => handleChangeStatus(moyen.id)} style={{ cursor: 'pointer' }}>
                            {moyen.statut}
                          </Badge>
                        </Col>
                        <Col md="3" className="text-right">
                          <Button color="danger" size="sm" onClick={() => handleDeleteMoyen(moyen.id)} style={{ borderRadius: '8px' }}>Supprimer</Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Modal pour ajouter un moyen de paiement */}
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Ajouter un Moyen de Paiement</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="type">Type</Label>
              <Input type="text" name="type" id="type" placeholder="Type de paiement" value={newMoyen.type} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="details">Détails</Label>
              <Input type="text" name="details" id="details" placeholder="Détails (ex: Visa se terminant par 4242)" value={newMoyen.details} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
              <Label for="statut">Statut</Label>
              <Input type="select" name="statut" id="statut" value={newMoyen.statut} onChange={handleInputChange}>
                <option value="Actif">Actif</option>
                <option value="Inactif">Inactif</option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleAddMoyen}>Ajouter</Button>
            <Button color="secondary" onClick={toggleModal}>Annuler</Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};

export default MoyenDePaiementClient;
