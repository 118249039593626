// ImageDropzone.js
import React, { useState } from 'react';


function ImageDropzone({ onFileSelected }) {
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onFileSelected(e.dataTransfer.files[0]);
    }
  };

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };
  const handleChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    onFileSelected(file);
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const dropzoneStyle = {
    border: '2px dashed #ccc',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '20px'
  };

  return (
    <div
      style={dropzoneStyle}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      Glissez votre image ici ou cliquez pour sélectionner
      {imagePreviewUrl && (
        <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '100%', marginTop: '20px' }} />
      )}
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </div>
  );
}

export default ImageDropzone;
