import React from "react";

import {
    Button,
    Col,
    ListGroup,
    ListGroupItem,
    Row,
} from "reactstrap";
import HeaderA from "components/Headers/HeaderA.js";

const MyComponent = () => {

    return (
        <>

            <HeaderA />

           

            <ListGroup className="list my--3" flush>
                <ListGroupItem
                    className="px-0"
                    style={{
                        width: 'calc(100% - 40px)',  // Ajuste la largeur pour tenir compte de 20px de marge de chaque côté
                        margin: '0 20px',            // Marge de 20px à gauche et à droite
                        borderRadius: '15px',        // Coins arrondis
                        paddingLeft: '10px'
                    }}
                >
                    <Row className="align-items-center">
                        <Col className="col-auto" style={{ marginLeft: '20px' }}>
                            <a
                                className="avatar rounded-circle"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                <img
                                    alt="..."
                                    src={require("assets/img/theme/doctolib.jpg").default}
                                />
                            </a>
                        </Col>
                        <div className="col ml--2">
                            <h4 className="mb-0">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                    Doctolib
                                </a>
                            </h4>
                            <span className="text-success">●</span>
                            <small>Disponible</small>
                        </div>
                        <Col className="col-auto" style={{ marginRight: '30px' }}>
                            <Button color="primary"
                                size="lg"
                                type="button"
                                style={{ width: '150px', padding: '7px' }} >
                                Connexion
                            </Button>
                        </Col>
                    </Row>
                </ListGroupItem>
                <ListGroupItem
                    className="px-0"
                    style={{
                        width: 'calc(100% - 40px)',  // Ajuste la largeur pour tenir compte de 20px de marge de chaque côté
                        margin: '0 20px',            // Marge de 20px à gauche et à droite
                        borderRadius: '15px',        // Coins arrondis
                        paddingLeft: '10px'
                    }}
                >
                    <Row className="align-items-center">
                        <Col className="col-auto" style={{ marginLeft: '20px' }}>
                            <a
                                className="avatar rounded-circle"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                <img
                                    alt="..."
                                    src={require("assets/img/theme/vitale.jpg").default}
                                />
                            </a>
                        </Col>
                        <div className="col ml--2">
                            <h4 className="mb-0">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                    Sesame Vitale
                                </a>
                            </h4>
                            <span className="text-success">●</span>
                            <small>Disponible</small>
                        </div>
                        <Col className="col-auto" style={{ marginRight: '30px' }}>
                            <Button color="primary"
                                size="lg"

                                type="button"
                                style={{ width: '150px', padding: '7px' }} >
                                Connexion
                            </Button>
                        </Col>
                    </Row>
                </ListGroupItem>
                <ListGroupItem
                    className="px-0"
                    style={{
                        width: 'calc(100% - 40px)',  // Ajuste la largeur pour tenir compte de 20px de marge de chaque côté
                        margin: '0 20px',            // Marge de 20px à gauche et à droite
                        borderRadius: '15px',        // Coins arrondis
                        paddingLeft: '10px'
                    }}
                >
                    <Row className="align-items-center">
                        <Col className="col-auto" style={{ marginLeft: '20px' }}>
                            <a
                                className="avatar rounded-circle"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                <img
                                    alt="..."
                                    src={require("assets/img/theme/google.jpg").default}
                                />
                            </a>
                        </Col>
                        <div className="col ml--2">
                            <h4 className="mb-0">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                    Google
                                </a>
                            </h4>
                            <span className="text-success">●</span>
                            <small>Disponible</small>
                        </div>
                        <Col className="col-auto" style={{ marginRight: '30px' }}>
                            <Button color="primary"
                                size="lg"
                                type="button"
                                style={{ width: '150px', padding: '7px' }} >
                                Connexion
                            </Button>
                        </Col>
                    </Row>
                </ListGroupItem>
            </ListGroup >
        </>
    );
};

export default MyComponent;
